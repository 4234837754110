import React, { useState } from 'react';
import { Link } from 'gatsby';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import useSitedataAssets from 'hooks/useSitedataAssets';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SideMenu from 'components/SideMenu';
import FCSidebar from './FCSidebar';

interface FCEditDocumentLayoutProps {
  editorName?: string;
  components: {
    doumentInfo: React.ReactNode;
    avatarMenu: React.ReactNode;
    saveAsTextBlockButton: React.ReactNode;
    formatButtonBold?: React.ReactNode;
    formatButtonItalic?: React.ReactNode;
    formatButtonUnderline?: React.ReactNode;
    formatButtonJustifyLeft?: React.ReactNode;
    formatButtonJustifyCenter?: React.ReactNode;
    formatButtonJustifyRight?: React.ReactNode;
    formatButtonJustifyFull?: React.ReactNode;
    formatButtonOutdent?: React.ReactNode;
    formatButtonOrderedList?: React.ReactNode;
    formatButtonUnorderedList?: React.ReactNode;
    formatButtonIndent?: React.ReactNode;
    formatButtonLink?: React.ReactNode;
    formatsDropdown?: React.ReactNode;
    editor: React.ReactNode;
    fileButton: React.ReactNode;
    previewButton: React.ReactNode;
  };
}

const FCEditDocumentLayout: React.FC<FCEditDocumentLayoutProps> = ({ editorName, components }) => {
  const { logo } = useSitedataAssets();
  const [showFormats, setShowFormats] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  // adapt for iPad width
  const layout = useLayout();
  const toggleSideMenu = () => setShowSideMenu(value => !value);
  const toggleShowFormats = () => setShowFormats(value => !value);
  const tinymceLayout = editorName === 'tinymce';

  const preventEditorFocusLoss = e => e.preventDefault();

  switch (layout) {
    case 'mobile':
      return (
        <StyledMobileLayout className="edit-document mobile styleguide-v2">
          <div className="header-fixed">
            <header>
              <StyledMenuButton onClick={toggleSideMenu} className="menu-button">
                <Icon name="menu-hamburger" />
              </StyledMenuButton>
              <div className="info">{components.doumentInfo}</div>
              {components.avatarMenu}
            </header>
            {!tinymceLayout && (
              <div className="editor-controls">
                {showFormats ? (
                  <>
                    {components.formatButtonItalic}
                    {components.formatButtonUnderline}
                    {components.formatButtonBold}
                    {components.formatButtonJustifyLeft}
                    {components.formatButtonJustifyCenter}
                    {components.formatButtonJustifyRight}
                    {components.formatButtonJustifyFull}
                    {components.formatButtonOutdent}
                    {components.formatButtonIndent}
                    {components.formatButtonLink}
                    <Button onClick={toggleShowFormats} className="button-close">
                      <Icon name="close" size={8} />
                    </Button>
                  </>
                ) : (
                  <>
                    {components.formatsDropdown}
                    <Button onClick={toggleShowFormats} className="text-styles-button">
                      <Icon name="fc-bold" /> Text Styles
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="content">
            {components.editor}
            {components.saveAsTextBlockButton}
          </div>
          <StyledSideMenu
            open={showSideMenu}
            onClose={toggleSideMenu}
            position="left"
            className="scrollable-fct">
            <div className="side-menu-content">
              <FCSidebar
                buttons={[components.fileButton, components.previewButton]}
                onClose={toggleSideMenu}
              />
            </div>
          </StyledSideMenu>
        </StyledMobileLayout>
      );

    case 'tablet':
      return (
        <StyledTabletLayout
          className="edit-document tablet styleguide-v2"
          data-testid="edit-document">
          <header>
            <StyledMenuButton onClick={toggleSideMenu} className="menu-button">
              <Icon name="menu-hamburger" />
            </StyledMenuButton>

            {components.saveAsTextBlockButton}

            {!tinymceLayout && (
              <>
                {components.formatsDropdown}
                {components.formatButtonItalic}
                {components.formatButtonUnderline}
                {components.formatButtonBold}
                {components.formatButtonJustifyLeft}
                {components.formatButtonJustifyCenter}
                {components.formatButtonJustifyRight}
                {components.formatButtonJustifyFull}
                {components.formatButtonOutdent}
                {components.formatButtonIndent}
                {components.formatButtonLink}
              </>
            )}
            {components.fileButton}
            {components.previewButton}
            {components.avatarMenu}
          </header>

          <StyledSideMenu
            open={showSideMenu}
            onClose={toggleSideMenu}
            position="left"
            hideCloseButton>
            <FCSidebar />
          </StyledSideMenu>
          <div className="content">{components.editor}</div>
        </StyledTabletLayout>
      );

    default:
      return (
        <StyledDesktopLayout
          className="edit-document desktop styleguide-v2"
          data-testid="edit-document">
          <header onMouseDown={preventEditorFocusLoss}>
            <Link to="/form-creation" className="logo-wrapper">
              {logo && <img src={logo} alt="logo" className="logo" />}
            </Link>

            <div className="document-controls">
              <div className="info">{components.doumentInfo}</div>
              {components.saveAsTextBlockButton}

              {!tinymceLayout && (
                <>
                  {components.formatsDropdown}
                  <StyledEditorButtonGroup>
                    {components.formatButtonItalic}
                    {components.formatButtonUnderline}
                    {components.formatButtonBold}
                  </StyledEditorButtonGroup>
                  <StyledEditorButtonGroup>
                    {components.formatButtonJustifyLeft}
                    {components.formatButtonJustifyCenter}
                    {components.formatButtonJustifyRight}
                    {components.formatButtonJustifyFull}
                  </StyledEditorButtonGroup>
                  <StyledEditorButtonGroup>
                    {components.formatButtonOrderedList}
                    {components.formatButtonUnorderedList}
                  </StyledEditorButtonGroup>
                  <StyledEditorButtonGroup>
                    {components.formatButtonOutdent}
                    {components.formatButtonIndent}
                  </StyledEditorButtonGroup>
                  <StyledEditorButtonGroup>{components.formatButtonLink}</StyledEditorButtonGroup>
                </>
              )}

              {components.fileButton}
              {components.previewButton}
            </div>

            {components.avatarMenu}
          </header>
          <FCSidebar />
          <div className="content">{components.editor}</div>
        </StyledDesktopLayout>
      );
  }
};

export default FCEditDocumentLayout;

const StyledDesktopLayout = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'sidebar content';
  grid-template-rows: 80px 1fr;
  grid-template-columns: auto 1fr;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  position: relative;

  header {
    grid-area: header;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
    margin: 0 40px 0 30px;

    .logo-wrapper {
      width: 253px;
      flex-shrink: 0;
    }

    .document-controls {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 18px;

      .info {
        margin-right: auto;
        white-space: nowrap;

        .title {
          font-size: 16px;
          line-height: 24px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          white-space: normal;
        }

        .address {
          font-size: 12px;
          line-height: 16px;
          color: ${props => props.theme.colors.grayDark};
        }

        .new-template {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .formats-dropdown {
        margin-left: 40px;
        flex-shrink: 0;
        height: 24px;
      }

      .button-file {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        background: ${props => props.theme.colors.pink};
        color: ${props => props.theme.colors.red};
        fill: ${props => props.theme.colors.red};
        margin: 0 12px 0 auto;

        &.disabled {
          fill: ${props => props.theme.colors.grayDark};
          color: ${props => props.theme.colors.grayDark};
          background: ${props => props.theme.colors.gray};
        }

        .icon {
          margin-right: 6px;
        }
      }

      .button-preview {
        width: 150px;
        white-space: nowrap;
      }
    }

    .avatar-menu {
      margin-left: auto;
    }
  }

  .sidebar {
    grid-area: sidebar;
    overflow: hidden;
  }

  .content {
    grid-area: content;
    padding: 24px 18px;
    overflow-y: auto;
    min-width: 913px;

    .form-editor {
      margin-left: auto;
      margin-right: auto;
      width: 725px;
      min-height: 960px;
      padding: 50px;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    }
  }

  .sidebar.expand + .content {
    min-width: auto;
  }
`;

const StyledTabletLayout = styled(StyledDesktopLayout)`
  grid-template-areas:
    'header'
    'content';
  grid-template-rows: 80px 1fr;
  grid-template-columns: auto;
  min-height: 100vh;

  header {
    flex-shrink: 0;
    margin: 0 20px;
    border-bottom: 1px solid #f1f1f1;

    .editor-button {
      margin: 0 4px;
    }

    .menu-button {
      margin-right: 20px;
    }

    .formats-dropdown {
      width: 95px;
      margin-left: 21px;
      margin-right: 4px;
      height: 24px;
    }

    .button-file {
      margin: 0 8px 0 auto;
      flex-shrink: 0;
    }

    .button-preview {
      width: 150px;
    }

    .avatar-menu {
      margin: 0 12px 0 24px;
    }
  }

  .content {
    min-width: initial;
  }
`;

const StyledMobileLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  box-sizing: border-box;

  header {
    display: flex;
    padding: 12px 0;
    margin: 0 10px 12px 10px;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.grayDark};
    box-sizing: border-box;

    .info {
      text-align: center;
      overflow: hidden;

      .title {
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .address {
        font-size: 12px;
        line-height: 16px;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }

  .editor-controls {
    padding: 0 10px;
    height: 32px;
    flex-shrink: 0;

    .formats-dropdown {
      width: 86px;
      display: inline-block;
      height: 32px;

      .dropdown-options {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .text-styles-button {
      width: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      color: black;
      border: 1px solid black;
      fill: black;
      float: right;

      .icon {
        width: 10px;
        height: 10px;
        margin-right: 8px;
      }
    }

    .editor-button {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin: 0 3px;
    }

    .button-close {
      padding: 0;
      width: 24px;
      height: 24px;
      float: right;
      flex-shrink: 0;
    }
  }

  .content {
    padding: 0 10px 16px 10px;
    margin-top: 16px;
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    .button-save-block {
      position: absolute;
      box-sizing: border-box;
      bottom: 26px;
      left: 20px;
      width: calc(100% - 40px);
    }
  }
`;

const StyledSideMenu = styled(SideMenu)`
  width: 100%;

  .side-menu-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .side-menu-title {
      margin-bottom: 3px;
      line-height: 24px;
      font-weight: 500;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: auto;
    min-width: initial;
    padding: 0;
    justify-content: flex-start;

    .sidebar {
      height: 100%;
    }
  }
`;

const StyledEditorButtonGroup = styled.div`
  display: flex;
  margin: 0 4px;
`;

const StyledMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  padding: 3px;
`;
