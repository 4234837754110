import React, { useState, useEffect, useContext } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import notify from 'notify';
import { styled } from 'styles';
import Button from 'components/Button';
import FCSaveToProjectModal from '../FCModals/FCSaveToProjectModal';
import FCPreviewLayout from './FCPreviewLayout';
import { PreviewData } from '../types';
import FCTContext from '../FCTContext';

// react-pdf is giving "Critical dependency" warnings
// hopefully it will be fixed in the upcoming update
// https://github.com/wojtekmaj/react-pdf/issues/280/
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FCPreview = () => {
  const {
    document,
    project,
    openSaveToProjectModal,
    getPreviewContentAsPDF,
    setShowPreviewScreen,
    showSignature
  } = useContext(FCTContext);
  const [previewData, setPreviewData] = useState<PreviewData>();
  const [numPages, setNumPages] = useState();

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const getDocumentPreviewRequest = async () => {
      try {
        const pdfData = await getPreviewContentAsPDF();
        setPreviewData(pdfData);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        notify('Error fetching preview data.');
      }
    };
    getDocumentPreviewRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!previewData) return <>Loading...</>;

  return (
    <>
      <FCPreviewLayout
        document={document}
        documentName={document?.name || project?.name}
        onReturnClick={() => setShowPreviewScreen(false)}
        components={{
          saveToProjectButton: (
            <Button
              onClick={() => openSaveToProjectModal({ signature: false })}
              data-cy="button_save">
              Save to project
            </Button>
          ),
          // XP-1774
          saveWithSignatureButton: false && showSignature && (
            <ButtonPink
              onClick={() => openSaveToProjectModal({ signature: true })}
              data-cy="button_save_send_signature">
              Save and send for signature
            </ButtonPink>
          ),
          document: (
            <Document
              renderMode="svg"
              file={`data:application/pdf;base64,${previewData.data}`}
              onLoadSuccess={handleLoadSuccess}
              // eslint-disable-next-line no-console
              onLoadError={console.error}
              className="document">
              {numPages &&
                new Array(numPages)
                  .fill(null)
                  // eslint-disable-next-line react/no-array-index-key
                  .map((_, idx) => <Page key={idx} pageNumber={idx + 1} width={820} />)}
            </Document>
          )
        }}
      />
      <FCSaveToProjectModal />
      {/*       <FCSaveToProjectModal
        data={saveToProject}
        onClose={() => setSaveToProject(undefined)}
        onSuccess={handleSaveToProjectSuccess}
      /> */}
    </>
  );
};

export default FCPreview;

const ButtonPink = styled(Button)`
  background: ${props => props.theme.colors.pink};
  color: ${props => props.theme.colors.red};
  fill: ${props => props.theme.colors.red};
`;
