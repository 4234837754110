import React from 'react';
import TinyMCEContext, { TinyMCEContextWrapper } from '../editors/tinymce/TinyMCEContext';
import TMCEditor from '../editors/tinymce/FCEditor';

// import SlateContext, { SlateContextWrapper } from '../editors/slate/SlateContext';
// import Slateditor, { FCEditorButton as SlateEditorButton } from '../editors/slate';
// import TMCEEditorButton from '../editors/tinymce/FCEditorButton';
// import TMCEditorFormatsDropdown from '../editors/tinymce/FCEditorFormatsDropdown';
// import SlateditorFormatsDropdown from '../editors/slate/FCEditorFormatsDropdown';
import { EditorContextValues } from '../types';

type EditorType = 'slate' | 'tinymce';

type ConfigValues = {
  editorName: EditorType;
  editorContext: React.Context<EditorContextValues>;
  editorContextWrapper: React.FC<any>;
  editor: React.FC<any>;
  // editorButton: React.FC<any>;
  // editorFormatsDropdown: React.FC<any>;
};

export const tinymce: ConfigValues = {
  editorName: 'tinymce',
  editorContext: (TinyMCEContext as unknown) as React.Context<EditorContextValues>,
  editorContextWrapper: TinyMCEContextWrapper,
  editor: TMCEditor
};

// export const slate: ConfigValues = {
//   editorName: 'slate',
//   editorContext: SlateContext,
//   editorContextWrapper: SlateContextWrapper,
//   editor: Slateditor,
//   editorButton: SlateEditorButton,
//   editorFormatsDropdown: SlateditorFormatsDropdown
// };

export const useFCTconfig = (): ConfigValues => {
  //   const editor = editorType === 'tinymce' ? tinymce : slate;
  // const editor = slate;
  const editor = tinymce;
  return editor;
};
