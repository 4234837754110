import React from 'react';
import clsx from 'clsx';
import { styled } from 'styles';
import Icon from 'components/Icon';

interface FoldersListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  hasNodes?: boolean;
}

const FoldersListItem: React.FC<FoldersListItemProps> = ({
  name,
  className,
  hasNodes,
  ...props
}) => (
  <StyledFoldersListItem className={clsx('folders-list-item', className)} {...props}>
    <Icon name="folder" size={16} />
    {name}
    {hasNodes && <Icon name="chevron" size={16} />}
  </StyledFoldersListItem>
);

export default FoldersListItem;

const StyledFoldersListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: none;
  background: none;
  margin-left: 16px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  word-break: break-all;

  &.open {
    background: ${props => props.theme.colors.seashell};

    .icon-folder {
      fill: ${props => props.theme.colors.grayDark};
    }
  }

  &:hover {
    opacity: 0.7;
  }

  .icon {
    flex-shrink: 0;
  }

  .icon-folder {
    fill: ${props => props.theme.colors.gray};
    margin-right: 8px;
  }

  .icon-chevron {
    transform: rotate(-90deg);
    margin-left: auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 8px 6px 8px 12px;
  }

  @media (width: 768px) {
    padding: 8px 6px 8px 12px;
  }
`;
