import React, { useCallback, useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { styled } from 'styles';

import { timestampToDate } from 'utils/formatting';
import Button from 'components/Button';
import Icon from 'components/Icon';
import InputRename from '../components/InputRename';
import { TreeNode } from '../types';
import { FCBrowserContext } from './FCBrowserWrapper';
import MobileBrowserSidebar from './MobileBrowserSidebar';
import RenameForm from './RenameForm';

const BrowserSidebar: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const {
    currentRootFolder: selected,
    sidebarItems: items,
    searchString: search,
    isMobile,
    openFolder,
    createFolder,
    setNodePopupData,
    editingNode
  } = useContext(FCBrowserContext);
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  const saveNewFolder = useCallback(() => {
    createFolder(newFolderName);
    setIsCreatingNewFolder(false);
    setNewFolderName('');
  }, [createFolder, newFolderName]);

  const handleFormClick = (e, data: TreeNode) => {
    e.persist();
    e.stopPropagation();
    setNodePopupData(value => (value?.target ? undefined : { data, target: e.target }));
  };

  const renderFolders = useCallback(() => {
    if (!items || !items.length) return null;

    const renderFolder = (item: TreeNode, idx) => {
      const { id, type, name, dateUpdated } = item;
      const isFolder = type !== 'form';
      const isFavorite = isFolder && !id;
      const isEditingNode = editingNode?.id === id && editingNode?.type === 'form';
      const isEditingFolder = editingNode?.id === id && editingNode?.isFolder;

      const templateNode = (
        <a href={`/form-creation/${id}`} key={idx}>
          <span className="name">
            <Icon name="fc-file" />
            {isEditingNode ? <RenameForm value={name} /> : name}
          </span>
          <span className="menu">
            <Button simple onClick={e => handleFormClick(e, item)} className="menu__button">
              <Icon name="menu-dots" />
            </Button>
          </span>
          <span className="date">
            {item.dateUpdated && `Edited ${timestampToDate(dateUpdated, 'MM/DD/YYYY')}`}
          </span>
        </a>
      );

      const folderNode = (
        <div
          key={idx}
          className={clsx('folder', { selected: selected === id })}
          onClick={() => openFolder(item as TreeNode)}
          role="button"
          tabIndex={0}
          data-cy={`folder_${idx}`}>
          <Icon className="folder__icon" name={item.type === 'favorite' ? 'star' : 'fc-document'} />
          {isEditingFolder ? <RenameForm value={name} /> : name}
          {!isFavorite && (
            <Button
              simple
              onClick={e => handleFormClick(e, item)}
              className="menu__button"
              data-cy={`folder_${idx}_menu`}>
              <Icon name="menu-dots" />
            </Button>
          )}
        </div>
      );

      return isFolder ? folderNode : templateNode;
    };

    return items.map(renderFolder);
    // eslint-disable-next-line
  }, [items, selected, openFolder]);

  const folderList = useMemo(() => renderFolders(), [renderFolders]);

  const mobileLayout = useMemo(
    () => (
      <>
        <MobileBrowserSidebar>{folderList}</MobileBrowserSidebar>
      </>
    ),
    [folderList]
  );

  const desktopLayout = useMemo(
    () => (
      <div className="folders">
        <h3>Saved Templates</h3>
        {folderList}
        <StyledNewFolder>
          {isCreatingNewFolder ? (
            <>
              <Icon name="fc-document" />
              <InputRename
                value={newFolderName}
                onChange={e => setNewFolderName(e.target.value)}
                onEnter={saveNewFolder}
                data-cy="input_new_folder"
              />
              <button
                onClick={() => setIsCreatingNewFolder(false)}
                className="new-folder-cancel"
                data-cy="button_new_folder_cancel">
                Cancel
              </button>
              <button
                className="new-folder-save"
                onClick={saveNewFolder}
                data-cy="button_new_folder_save">
                Save
              </button>
            </>
          ) : (
            <>
              <span
                onClick={() => setIsCreatingNewFolder(true)}
                className="new-folder"
                role="button"
                tabIndex={0}
                data-cy="create_new_folder">
                <Icon name="plus-mini" />
                Create new folder
              </span>
            </>
          )}
        </StyledNewFolder>
      </div>
    ),
    [folderList, isCreatingNewFolder, newFolderName, saveNewFolder]
  );

  return search || (isMobile && selected) ? null : (
    <StyledBrowserSidebar className="sidebar" data-cy="document_browser_sidebar">
      {isMobile ? mobileLayout : desktopLayout}
    </StyledBrowserSidebar>
  );
};

export default React.memo(BrowserSidebar);

const StyledBrowserSidebar = styled.aside`
  flex-shrink: 0;
  padding: 20px 0;
  width: 280px;
  font-size: 14px;
  line-height: 24px;
  border-right: 1px solid ${props => props.theme.colors.seashell};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    overflow: auto;
    overflow: overlay;
  }

  h3 {
    margin: 4px 0 11px;
    padding: 0 40px;
    color: ${props => props.theme.colors.grayDark};
  }

  .folders {
    .folder,
    & > a,
    & > div {
      padding: 4px 10px 4px 40px;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;
      word-break: break-word;

      .icon:not(.icon-menu-dots, .icon-plus-mini) {
        margin-right: 9px;
        flex-shrink: 0;
        fill: ${props => props.theme.colors.grayDark};
      }

      cursor: pointer;
    }

    .folder {
      &.selected {
        background: ${props => props.theme.colors.seashell};
      }
      &__icon {
        margin-right: 10px;
      }
    }

    .menu__button {
      margin-left: auto;

      .icon {
        fill: ${props => props.theme.colors.gray};
      }

      &:focus {
        .icon {
          fill: ${props => props.theme.colors.red};
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 10px 20px;
    width: -webkit-fill-available;

    .folders {
      margin-top: 17px;

      .folder,
      & > a {
        position: relative;
        padding: 13px 0 13px 3px;
        border-top: 1px solid ${props => props.theme.colors.seashell};
        word-break: break-word;

        &:first-of-type {
          border-top: none;
        }

        .icon {
          margin-right: 13px;
        }

        &[href] {
          flex-flow: wrap;
          align-items: center;

          .name {
            width: 95%;
            display: inline-flex;
            align-items: center;
          }

          .menu {
            margin-left: auto;

            .icon {
              fill: ${props => props.theme.colors.gray};
              margin-right: 0;
            }

            &__button:focus {
              .icon {
                fill: ${props => props.theme.colors.red};
              }
            }
          }

          .date {
            flex-basis: 100%;
            margin-top: 4px;
            padding-left: 22px;
            display: block;
            color: ${props => props.theme.colors.gray};
          }
        }
      }

      .folder {
        &:after {
          content: '›';
          position: absolute;
          right: 3px;
          font-weight: bold;
        }

        &.selected {
          background: transparent;
        }
      }
    }
  }
`;

const StyledNewFolder = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 40px;
  height: 32px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;

  .icon {
    flex-shrink: 0;

    &.icon-fc-document {
      fill: ${props => props.theme.colors.gray};
      margin-right: 9px;
    }

    &.icon-plus-mini {
      fill: ${props => props.theme.colors.red};
      margin-right: 11px;
    }
  }

  input {
    margin-right: auto;
    caret-color: ${props => props.theme.colors.red};
  }

  .new-folder {
    color: ${props => props.theme.colors.red};
  }

  .new-folder-cancel,
  .new-folder-save {
    border: none;
    background: none;
    padding: 0;
    white-space: nowrap;
    font-size: 12px;
    line-height: 18px;
  }

  .new-folder-cancel {
    color: ${props => props.theme.colors.red};
    margin: 0 12px;
  }

  .new-folder-save {
    color: ${props => props.theme.colors.grayDark};
  }
`;
