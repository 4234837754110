import { fetchWithToken } from 'utils/fetch';
import { checkSuccess, createFormData, urlEncodeBody } from 'utils/requests';
import {
  Form,
  FormTree,
  PreviewData,
  Template,
  TextBlockApiData,
  TextBlocksTree,
  VariablesTree,
  VariableValue
} from './types';

import { mapFormFolderTree, mapFavorites, mapTextBlocksTree, mapVariablesTree } from './mapping';

const path = `/form_creation`;

// get projects and document categories for "save to project" modal
export async function getDocumentsProjects(): Promise<{
  projects: {
    pid: number;
    address_1: string;
    address_2: string;
    propinfo: string;
    status: 0 | 1 | 2;
  }[];
  documentnames: {
    doccat: string;
    docname: string;
    doctype: string;
  }[];
}> {
  const response = await fetchWithToken(`${path}/document_types`);
  const data = await response.json();
  checkSuccess(data);
  return data;
}

// create/update form
// returns form id
export async function saveForm(form: Form): Promise<number> {
  const formData = createFormData({
    name: form.name,
    folder_id: form.folder,
    data: form.data,
    form_id: form.id
  });
  const response = await fetchWithToken(`${path}/forms/form/save_form`, {
    method: 'POST',
    body: formData
  });
  const data = await response.json();

  checkSuccess(data);
  return data.formId;
}

// rename folder request with the only parameter name
// returns folders tree
export async function renameFolder(id: number, name: string): Promise<FormTree> {
  const body = urlEncodeBody({ name });
  const response = await fetchWithToken(`${path}/forms/folder/${id}`, { method: 'PUT', body });
  const data = await response.json();

  checkSuccess(data);
  return mapFormFolderTree(data);
}

// load Form
export async function getForm(id: string) {
  const response = await fetchWithToken(`${path}/forms/form/${id}`);
  const data = await response.json();

  checkSuccess(data);
  return {
    id,
    uniqueId: data.unique_id ? String(data.unique_id) : undefined,
    folder: data.folderId ? Number(data.folderId) : undefined,
    name: data.name,
    data: data.data
  };
}

// load Template
export async function getTemplate(id: string): Promise<Template> {
  const response = await fetchWithToken(`${path}/forms/template/${id}`);
  const data = await response.json();

  if (!data.pre_form) throw Error('Error getting document template');
  return {
    form: data.pre_form,
    uniqueId: data.unique_id,
    propertyId: data.pid,
    docType: data.doctype
  };
}

// save Form to Property
export async function generatePdfAndSaveToProject(document: {
  name: string;
  type: string;
  data: string;
  project: number;
  uniqueId?: string;
  headerFooter?: boolean;
  signatures?: boolean;
}): Promise<PreviewData> {
  const payload = {
    name: document.name,
    document_type: document.type,
    listing_id: document.project,
    content: document.data,
    unique_id: document.uniqueId,
    needs_header_footer: document.headerFooter ? 1 : 0,
    needs_signatures: document.signatures ? 1 : 0
  };
  if (!payload.unique_id) delete payload.unique_id;
  const body = urlEncodeBody(payload);
  const response = await fetchWithToken(`${path}/forms/pdf/generate_pdf`, { method: 'POST', body });
  const data = await response.json();
  checkSuccess(data);
  return data;
}

// get document pdf preview
export async function generatePDFpreview(document: {
  data: string;
  headerFooter?: boolean;
}): Promise<PreviewData> {
  const body = urlEncodeBody({
    content: document.data,
    needs_header_footer: document.headerFooter ? 1 : 0
  });
  const response = await fetchWithToken(`${path}/forms/pdf/generate_pdf_preview`, {
    method: 'POST',
    body
  });
  const data = await response.json();

  checkSuccess(data);
  return data;
}

// get variables v2
export async function getVariables(): Promise<VariablesTree> {
  const response = await fetchWithToken(`${path}/variables/names`);
  const data = await response.json();

  checkSuccess(data);
  return mapVariablesTree(data);
}

export async function getVariablesValues(propertyId: number): Promise<VariableValue[]> {
  const response = await fetchWithToken(`${path}/variables/values/${propertyId}`);
  const data = await response.json();

  // checkSuccess() is not applicable - there is no success field in a data returned
  return data;
}

// get folder full tree structure
export async function getFullFormFolderTree(): Promise<FormTree> {
  const response = await fetchWithToken(`${path}/forms/full_tree`);
  const data = await response.json();
  checkSuccess(data);
  return mapFormFolderTree(data);
}

// get favorite templates
export async function getFavoriteDocuments(): Promise<FormTree> {
  const response = await fetchWithToken(`${path}/forms/form/favorites`);
  const data = await response.json();
  checkSuccess(data);
  return mapFavorites(data);
}

// get text blocks tree structure
export async function getTextBlocks(): Promise<TextBlocksTree> {
  const response = await fetchWithToken(`${path}/modules/full_tree`);
  const data = await response.json();

  checkSuccess(data);
  return mapTextBlocksTree(data);
}

// edit (rename) text blocks folder
export async function editTextBlocksFolder(id: number, value: string): Promise<TextBlocksTree> {
  const body = createFormData({ name: value });
  const response = await fetchWithToken(`${path}/modules/folder/${id}`, { method: 'PUT', body });
  const data = await response.json();

  checkSuccess(data);
  return mapTextBlocksTree(data);
}

// delete text blocks folder
export async function deleteTextBlocksFolder(id: number): Promise<TextBlocksTree> {
  const response = await fetchWithToken(`${path}/modules/folder/${id}`, {
    method: 'DELETE'
  });
  const data = await response.json();

  // checkSuccess() is not applicable - there is no success field in a data returned
  return mapTextBlocksTree(data);
}

export async function saveTextBlock(textBlockData: TextBlockApiData): Promise<TextBlocksTree> {
  const body = createFormData({
    folder_id: textBlockData.folder,
    data: String(textBlockData.content),
    name: String(textBlockData.name),
    module_id: textBlockData.id
  });
  const response = await fetchWithToken(`${path}/modules/module/save_module`, {
    method: 'POST',
    body
  });
  const data = await response.json();
  checkSuccess(data);
  return mapTextBlocksTree(data);
}

export async function deleteTextBlock(id: number): Promise<TextBlocksTree> {
  const response = await fetchWithToken(`${path}/modules/module/${id}`, { method: 'DELETE' });
  const data = await response.json();
  checkSuccess(data);

  return mapTextBlocksTree(data);
}

// needs testing
export async function deleteMultipleTextBlocks(ids: number[]): Promise<void> {
  const responses = await Promise.all(
    ids.map(id => fetch(`${path}/modules/module/${id}`, { method: 'DELETE' }))
  );

  responses.forEach(async response => checkSuccess(await response.json()));
}

export async function createFormFolder(newFolder: {
  name: string;
  parentId?: number;
}): Promise<FormTree> {
  const body = createFormData(newFolder);
  const response = await fetchWithToken(`${path}/forms/folder`, { method: 'POST', body });
  const data = await response.json();
  // checkSuccess(data);
  return data;
}

// create new text blocks folder
export async function createTextBlocksFolder({
  name,
  parentId = 0
}: {
  name: string;
  parentId?: number;
}): Promise<TextBlocksTree> {
  const body = createFormData({ parentId, name });
  const response = await fetchWithToken(`${path}/modules/folder`, {
    method: 'POST',
    body
  });
  const data = await response.json();

  // checkSuccess() is not applicable - there is no success field in a data returned
  return mapTextBlocksTree(data);
}

// delete form
export async function deleteForm(id: number): Promise<FormTree> {
  /* Returns 422 if request fails (e.g. we pass non-existing id, or don't pass id at all),
      401 - for unauthorized request,
     returns 200 and data = null if request successful.
     Also returns 200 if url is incorrect (e.g. typo) !!!
  */
  const response = await fetchWithToken(`${path}/forms/form/${id}`, { method: 'DELETE' });
  const data = await response.json();

  checkSuccess(data);
  return mapFormFolderTree(data);
}

// delete all forms from the folder
export async function deleteFolderWithForms(
  folderId: number,
  formIds: number[]
): Promise<FormTree> {
  const responses = await Promise.all(
    formIds.map(id => fetch(`${path}/forms/form/${id}`, { method: 'DELETE' }))
  );
  responses.forEach(async response => checkSuccess(await response.json()));

  return deleteFolder(folderId);
}

// delete folder with forms
export async function deleteFolder(id: number): Promise<FormTree> {
  const response = await fetchWithToken(`${path}/forms/folder/${id}`, { method: 'DELETE' });
  const data = await response.json();

  checkSuccess(data);
  return mapFormFolderTree(data);
}
