import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import notify from 'notify';
import { styled } from 'styles';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';
import FCTContext from '../FCTContext';
import InputRename from '../components/InputRename';

const FolderOption = ({ children, ...props }) => {
  return (
    <StyledFolderOption {...props}>
      <Icon name="folder" /> {children}
    </StyledFolderOption>
  );
};

const FCSaveAsModal = () => {
  const {
    folders,
    addNewFormFolder,
    openFormSaveAs,
    handleFormSaveAs,
    closeFormSaveAs
  } = useContext(FCTContext);
  const { register, control, errors, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false);
  const foldersOptions: DropdownOption[] = folders.map(folder => ({
    value: folder.id,
    text: <FolderOption>{folder.name}</FolderOption>,
    displayText: folder.name
  }));

  const submit = async form => {
    try {
      await handleFormSaveAs(form);
      notify('Document saved!');
    } catch (err) {
      notify(err.message);
    }
  };

  const addNewFolder = async name => {
    setIsCreatingNewFolder(false);
    try {
      addNewFormFolder(name);
      notify('New folder added');
    } catch (err) {
      notify(err.message);
    }
  };

  const optionCreateNew = {
    value: 'new',
    text: (
      <FolderOption className="add-new">
        {isCreatingNewFolder ? (
          <InputRename
            onEnter={addNewFolder}
            onBlur={() => setIsCreatingNewFolder(false)}
            data-cy="input_name_field"
          />
        ) : (
          'Add new folder'
        )}
      </FolderOption>
    ),
    onClick: () => setIsCreatingNewFolder(true)
  };

  return (
    <StyledModal
      open={openFormSaveAs}
      modalTitle="Save as"
      onClose={closeFormSaveAs}
      data-cy="modal_confirm">
      <form onSubmit={handleSubmit(submit)}>
        <Input
          ref={register({
            required: 'Required',
            maxLength: {
              value: 50,
              message: 'Maximum 50 characters'
            }
          })}
          name="name"
          label="Name"
          placeholder="Enter name"
          error={errors.name?.message}
          data-cy="input_name"
        />
        <Controller
          control={control}
          name="folder"
          defaultValue=""
          rules={{ required: 'Required' }}
          render={controllerProps => (
            <Dropdown
              options={[...foldersOptions, optionCreateNew]}
              label="Folder"
              placeholder="Select"
              error={errors.folder?.message}
              data-cy="dropdown_folder"
              {...controllerProps}
            />
          )}
        />
        <Button
          disabled={!formState.isValid || !formState.isDirty}
          type="submit"
          data-cy="button_save">
          Save
        </Button>
      </form>
    </StyledModal>
  );
};

export default FCSaveAsModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }

  .modal-body {
    .input,
    .dropdown,
    .button {
      width: 100%;
    }

    .input,
    .dropdown {
      margin-bottom: 16px;
    }

    .button {
      height: 40px;
    }

    .dropdown .option.active {
      .icon-checkmark {
        display: none;
      }

      .icon-folder {
        fill: #979797;
      }
    }
  }
`;

const StyledFolderOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &.add-new {
    color: ${props => props.theme.colors.red};

    input {
      border: none;

      &::placeholder {
        color: ${props => props.theme.colors.red};
      }
    }
  }

  .icon-folder {
    width: 16px;
    height: 12px;
    position: absolute;
    left: -24px;
    fill: ${props => props.theme.colors.gray};
  }
`;
