import flatten from 'lodash/flatten';
import {
  FormTree,
  FullTreeApiResponse,
  FavoritesApiResponse,
  TextBlocksTreeApiResponse,
  VariablesTreeApiResponse,
  TextBlocksTree,
  VariablesTree,
  TextBlock,
  TextBlockApiData
} from './types';

// map full tree data fetched from the api
export const mapFormFolderTree = (data: FullTreeApiResponse): FormTree => {
  return data.map(item => ({
    id: item.id,
    parentId: item.parentId,
    name: String(item.name),
    companyFolder: Boolean(item.companyFolder),
    isFolder: true,
    type: 'folder',
    children: item.forms.map(form => ({
      id: form.id,
      name: String(form.name),
      parentId: item.id,
      dateUpdated: form.dateUpdated,
      timesOpened: form.timesOpened,
      isFavorite: Boolean(form.isFavorite),
      type: 'form'
    }))
  }));
};

// map favorites data fetched from the api
export const mapFavorites = (data: FavoritesApiResponse): FormTree => {
  return data.map(item => ({
    id: Number(item.formId),
    name: String(item.name),
    dateUpdated: item.dateUpdated,
    type: 'form',
    isFavorite: true
  }));
};

// map text blocks tree data fetched from the api
export const mapTextBlocksTree = (data: TextBlocksTreeApiResponse): TextBlocksTree => {
  const uniqueIds = Array.from(new Set(data.map(item => item.id)));
  const newData: TextBlocksTreeApiResponse = [];
  uniqueIds.forEach(id => {
    const item = data.find(item => item.id === id);
    if (item) newData.push(item);
  });

  const mapFolder = item => {
    const subfolders = newData.filter(d => d.parentId === item.id);
    const textBlocks = item.modules;
    return {
      id: Number(item.id),
      name: String(item.name),
      parentId: Number(item.parentId),
      type: 'block',
      folders: subfolders.map(mapFolder),
      children: textBlocks.map(block => ({
        id: Number(block.id),
        name: String(block.name),
        parentId: Number(item.id),
        data: String(block.text)
      }))
    };
  };

  const res = newData.map(mapFolder);
  return res;
};

export const mapVariablesTree = (data: VariablesTreeApiResponse): VariablesTree => {
  const folderKeys: string[] = Object.keys(data);
  return folderKeys.map(key => ({
    id: key,
    name: data[key].name,
    type: 'variable',
    children: data[key].variables.map(variable => ({
      id: variable.id,
      category: key,
      key: variable.variable,
      name: variable.description
    }))
  }));
};

export const mapTextBlockToTextBlockApiData = (value: TextBlock): TextBlockApiData => {
  return {
    id: value.id,
    name: value.name,
    folder: value.parentId,
    content: value.data
  };
};

export const mapDocuments = data => {
  const categories = [...new Set(data.map(item => item.doccat))];

  const docOptions = categories.map(category => ({
    label: category,
    options: data
      .filter(item => item.doccat === category)
      .map(item => ({ text: item.docname, value: item.doctype }))
      .sort((a, b) => a.text - b.text)
  }));

  const docOptionsFlat = flatten(
    docOptions.map(arr => [
      {
        disabled: true,
        text: arr.label,
        value: arr.label,
        search: arr.options.map(option => option.text).join(',')
      },
      ...arr.options
    ])
  );

  return docOptionsFlat;
};
