import React, { useContext } from 'react';

import Tabs from 'components/Tabs';
import Icon from 'components/Icon';
import { FCBrowserContext } from './FCBrowserWrapper';

export enum MobileBrowserTabs {
  MostUsed = 1,
  Saved = 2
}

const tabs = [
  {
    key: MobileBrowserTabs.MostUsed,
    text: (
      <>
        <Icon name="star" width={12} height={12} />
        Most used
      </>
    )
  },
  { key: MobileBrowserTabs.Saved, text: 'Saved templates' }
];

const MobileBrowserSidebar: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  const { mobileTab, toggleMobileTab } = useContext(FCBrowserContext);

  return (
    <>
      <Tabs
        items={tabs}
        activeTab={mobileTab}
        onSelect={(_, data) => toggleMobileTab(data?.key)}
        mobile
        centered
      />
      <div className="folders">{children}</div>
    </>
  );
};

export default React.memo(MobileBrowserSidebar);
