import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';
import { styled } from 'styles';
import notify from 'notify';
import FCTContext from '../FCTContext';

const FCSaveToProjectModal = () => {
  const {
    projectsOptions,
    categoriesOptions,
    handleSaveToProject,
    showSaveToProjectModal,
    saveToProjData: data,
    setShowSaveToProjectModal
  } = useContext(FCTContext);
  const { register, control, errors, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: data?.name || data?.project?.name || '',
      project: data?.project?.id,
      type: data?.project?.docType,
      signatures: data?.signatures
    }
  });

  const submit = async form => {
    try {
      handleSaveToProject(form);
      notify('Save successful');
    } catch (err) {
      notify(err.message);
    }
  };

  return (
    <StyledModal
      open={showSaveToProjectModal}
      modalTitle={data?.signatures ? 'Save and send to signature' : 'Save to project'}
      onClose={() => setShowSaveToProjectModal(false)}>
      <form onSubmit={handleSubmit(submit)}>
        <Input
          ref={register({ required: 'Required' })}
          name="name"
          label="Name"
          placeholder="Enter name"
          error={errors.name?.message}
        />
        <Controller
          control={control}
          name="project"
          defaultValue=""
          rules={{ required: 'Required' }}
          render={controllerProps => (
            <Dropdown
              search
              options={projectsOptions}
              label="Project"
              placeholder="Select"
              error={errors.project?.message}
              data-cy="dropdown_project"
              {...controllerProps}
            />
          )}
        />

        <Controller
          control={control}
          name="type"
          defaultValue=""
          rules={{ required: 'Required' }}
          render={controllerProps => (
            <Dropdown
              search
              options={categoriesOptions}
              label="Document type"
              placeholder="Select"
              error={errors.type?.message}
              data-cy="dropdown_document_type"
              {...controllerProps}
            />
          )}
        />
        <Button type="submit" disabled={!formState.isValid || !formState.isDirty}>
          Save
        </Button>
      </form>
    </StyledModal>
  );
};

export default FCSaveToProjectModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }

  .modal-body {
    .input,
    .dropdown,
    .button {
      width: 100%;
    }

    .input,
    .dropdown {
      margin-bottom: 16px;
    }

    .button {
      height: 40px;
    }
  }
`;
