import React from 'react';
import { styled } from 'styles';

import useSitedataAssets from 'hooks/useSitedataAssets';
import HeaderAvatarMenu from 'components/HeaderAvatarMenu';
import FCBrowserWrapper from './FCBrowserWrapper';
import BrowserLayout from './BrowserLayout';

const FCBrowser: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const { logo } = useSitedataAssets();
  return (
    <FCBrowserWrapper>
      <StyledFCBrowser className="browser" data-testid="fc-browser">
        <header>
          <a href="/form-creation" className="logo-wrapper" data-cy="link_logo_return">
            {logo && <img src={logo} alt="logo" className="logo" />}
          </a>
          <HeaderAvatarMenu />
        </header>
        <BrowserLayout />
      </StyledFCBrowser>
    </FCBrowserWrapper>
  );
};

export default FCBrowser;

const StyledFCBrowser = styled.div`
  display: flex;
  flex-direction: column;

  header {
    padding: 12px 0;
    margin: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  .fc-header {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  .container {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    header {
      padding: 12px 0;
      margin: 0 10px;
      border-bottom: 1px solid ${props => props.theme.colors.grayDark};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    height: 100%;
  }
`;
