import React, { useContext } from 'react';
import pluralize from 'pluralize';
import { styled } from 'styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { FCBrowserContext } from './FCBrowserWrapper';

const DeleteFolderModal = ({ ...props }) => {
  const { nodePopupData, deleteForm, setOpenDeleteFolderWarning } = useContext(FCBrowserContext);
  const forms = nodePopupData?.data?.children;

  const handleCancel = () => setOpenDeleteFolderWarning(false);

  return (
    <StyledDeleteFolderModal
      modalTitle={
        <>
          <Icon name="info" />
          Warning
        </>
      }
      onClose={handleCancel}
      {...props}
      data-cy="modal_delete_folder">
      <p>Deleting this folder will also delete all forms in it. Do you still want to delete it?</p>

      <StyledFormsList className="forms">
        <figcaption className="forms__heading">
          {forms?.length} {pluralize('form', forms?.length)}
        </figcaption>
        <ul className="forms__list scrollable">
          {forms?.map(({ id, name }) => (
            <li className="forms__list__item" key={id}>
              <Icon name="fc-file" />
              {name}
            </li>
          ))}
        </ul>
      </StyledFormsList>
      <Button
        onClick={() => setOpenDeleteFolderWarning(false)}
        large
        className="cancel-btn"
        data-cy="button_cancel_delete">
        No
      </Button>
      <Button onClick={deleteForm} large data-cy="button_confirm_delete">
        Yes
      </Button>
    </StyledDeleteFolderModal>
  );
};

export default React.memo(DeleteFolderModal);

const StyledDeleteFolderModal = styled(Modal)`
  .modal-content {
    min-width: 300px;
    width: 300px;
    font-size: 16px;
    line-height: 24px;
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .title > .icon {
    margin-right: 8px;
    fill: #ff7272;
  }

  .modal-body {
    p {
      font-size: 16px;
    }
  }

  .close-btn {
    padding: 0;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    border-radius: ${props => props.theme.misc.borderRadius};

    svg {
      width: 8px;
      height: 8px;
    }
  }

  button.large {
    width: 100%;
    margin-top: 16px;

    &.cancel-btn {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.pink};
    }
  }
`;

const StyledFormsList = styled.figure`
  margin-top: 18px;
  padding: 11px 6px 11px 12px;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid ${props => props.theme.colors.seashell};
  border-radius: ${props => props.theme.misc.borderRadius};

  .forms__heading {
    margin-bottom: 12px;
    font-weight: 500;
  }

  .forms__list {
    max-height: 224px;
    overflow-y: auto;

    &__item {
      padding: 15px 0;
      word-break: break-all;

      &:not(:first-of-type) {
        border-top: 1px solid ${props => props.theme.colors.seashell};
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 3px;
      }

      .icon {
        margin-right: 10px;
        fill: ${props => props.theme.colors.grayDark};
      }
    }
  }
`;
