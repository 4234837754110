import React from 'react';
import { styled } from 'styles';

const EmptyFolder: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => (
  <StyledEmptyFolder>{children}</StyledEmptyFolder>
);

export default React.memo(EmptyFolder);

const StyledEmptyFolder = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.grayDark};

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    align-items: center;
  }
`;
