import React from 'react';
import clsx from 'clsx';
import { styled } from 'styles';
import { timestampToDate } from 'utils/formatting';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { TreeNode } from '../types';
import RenameForm from './RenameForm';

interface TreeItemProps extends React.HTMLAttributes<HTMLElement> {
  item?: TreeNode;
  isMobile?: boolean;
  className?: string;
  editing: boolean;
  onClick: (value?: any) => void;
}

const TreeItem: React.FC<TreeItemProps> = ({ item, editing, onClick, className, ...props }) => {
  const { name, dateUpdated, type } = item || {};
  const isTemplate = type === 'form';
  const iconName = isTemplate ? 'fc-file' : 'fc-document';

  const dateLabel = isTemplate && dateUpdated && `Edited ${timestampToDate(dateUpdated)}`;

  const handleClick = e => {
    e.preventDefault();
    if (onClick) onClick(e);
  };

  return (
    <StyledTreeItem {...props} className={clsx({ folder: isTemplate }, className)}>
      <span className="name">
        <Icon name={iconName} />
        {editing ? <RenameForm value={name} /> : name}
      </span>
      <span className="date">{dateLabel}</span>
      {onClick && (
        <span className="menu">
          <Button simple className="menu__button" onClick={handleClick} data-cy="button_form_menu">
            <Icon name="menu-dots" />
          </Button>
        </span>
      )}
    </StyledTreeItem>
  );
};

export default TreeItem;

const StyledTreeItem = styled.a`
  padding: 16px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:not(:first-of-type) {
    border-top: 1px solid ${props => props.theme.colors.seashell};
  }

  span {
    display: flex;
    align-items: center;

    > .icon {
      margin-right: 10px;
    }
  }

  .name {
    flex: 1;
    flex-shrink: 0;
    padding-right: 15px;
    word-break: break-all;

    .icon {
      flex-shrink: 0;
      fill: ${props => props.theme.colors.grayDark};
      width: 12px;
      height: 12px;
    }
  }

  .date {
    flex: 1.5;
    color: ${props => props.theme.colors.gray};
  }

  .menu {
    padding: 2px 6px;
    text-align: right;

    .icon {
      fill: ${props => props.theme.colors.gray};
    }

    &__button:focus {
      .icon {
        fill: ${props => props.theme.colors.red};
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 8px 0 6px 2px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: 'name menu' 'date .';

    .name {
      grid-area: name;
      margin-bottom: 4px;
    }

    .date {
      grid-area: date;
      padding-left: 22px;
    }

    .menu {
      grid-area: menu;
      margin-left: auto;
      padding-right: 0;
    }
  }
`;
