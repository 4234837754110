import React, { useContext } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import PopMenu, { PopMenuProps } from 'components/PopMenu';
import { FCBrowserContext } from './FCBrowserWrapper';

interface FormTooltipProps extends PopMenuProps {
  onOutsideClick?: (event?: any) => void;
}

const defaultModifiers = [
  { name: 'flip', options: { fallbackPlacements: ['top-start', 'right'] } },
  { name: 'preventOverflow', options: { padding: 32 } },
  { name: 'offset', options: { offset: [0, 2] } }
];

const FormTooltip: React.FC<FormTooltipProps> = ({
  className,
  placement = 'bottom-end',
  onOutsideClick,
  ...props
}) => {
  const {
    nodePopupData,
    setOpenDeleteModal,
    setOpenDeleteFolderWarning,
    setNodePopupData,
    setEditingNode
  } = useContext(FCBrowserContext);
  const layout = useLayout();
  const modifiers =
    layout === 'mobile'
      ? [...defaultModifiers, { name: 'preventOverflow', options: { padding: 16 } }]
      : defaultModifiers;

  const handleRenameClick = () => {
    setNodePopupData({ ...nodePopupData, target: undefined });
    setEditingNode(nodePopupData?.data);
  };

  const handleDeleteClick = () => {
    setNodePopupData({ ...nodePopupData, target: undefined });
    if (nodePopupData?.data?.isFolder && nodePopupData.data.children?.length)
      setOpenDeleteFolderWarning(true);
    else setOpenDeleteModal(true);
  };

  return (
    <StyledPopMenu
      placement={placement}
      modifiers={modifiers}
      onOutsideClick={onOutsideClick}
      className={clsx('form-tooltip', className)}
      unmountChildren
      {...props}
      data-cy="form_tooltip">
      <ul className="form-tooltip__options">
        <li
          className="form-tooltip__option"
          onClick={handleRenameClick}
          role="button"
          data-cy="option_rename">
          Rename
        </li>
        <li
          className="form-tooltip__option"
          onClick={handleDeleteClick}
          role="button"
          data-cy="option_delete">
          Delete
        </li>
      </ul>
    </StyledPopMenu>
  );
};

export default FormTooltip;

const StyledPopMenu = styled(PopMenu)`
  width: 125px;
  padding: 0;

  .form-tooltip__options {
    padding: 2px 0;
  }

  .form-tooltip__option {
    padding: 7px 12px;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.seashell};
    }
  }
`;
