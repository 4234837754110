import React, { useContext } from 'react';

import useLayout from 'hooks/useLayout';
import { FCBrowserContext } from './FCBrowserWrapper';
import BrowserHeader from './BrowserHeader';
import BrowserSidebar from './BrowserSidebar';
import BrowserContent from './BrowserContent';
import DeleteFormModal from './DeleteFormModal';
import DeleteFolderModal from './DeleteFolderModal';
import FormTooltip from './FormTooltip';

const BrowserLayout: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const {
    currentRootFolder,
    searchString,
    nodePopupData,
    setNodePopupData,
    openDeleteModal,
    openDeleteFolderWarning
  } = useContext(FCBrowserContext);
  const layout = useLayout();

  const desktopLayout = (
    <>
      <BrowserHeader />
      <div className="container">
        <BrowserSidebar />
        <BrowserContent />
      </div>
    </>
  );

  const mobileLayout = () => {
    if (!currentRootFolder && !searchString) {
      return (
        <>
          <BrowserHeader className="mobile-header" />
          <BrowserSidebar />
        </>
      );
    }
    if (searchString) {
      return (
        <>
          <BrowserHeader className="mobile-header" />
          <BrowserContent />
        </>
      );
    }

    return <BrowserContent />;
  };

  return (
    <div className="browser-layout">
      {layout === 'mobile' ? mobileLayout() : desktopLayout}
      <FormTooltip
        target={nodePopupData?.target as any}
        open={Boolean(nodePopupData?.target)}
        onOutsideClick={() => setNodePopupData(value => ({ ...value, target: undefined }))}
      />
      <DeleteFormModal open={openDeleteModal} />
      <DeleteFolderModal open={openDeleteFolderWarning} />
    </div>
  );
};

export default React.memo(BrowserLayout);
