import React, { useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { styled } from 'styles';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';
import FCTContext from '../FCTContext';
import { getVariablesWithoutValues } from '../useFCVariables';

const FCPreviewModal = () => {
  const {
    showPreviewModal,
    setShowPreviewModal,
    handlePreviewModalSubmit,
    variablesInserted
  } = useContext(FCTContext);
  const { register, errors, handleSubmit } = useForm();

  const fieldsInserted = useMemo(() => getVariablesWithoutValues(variablesInserted), [
    variablesInserted
  ]);

  const onClose = () => setShowPreviewModal(false);

  return (
    <StyledModal open={showPreviewModal} onClose={onClose} modalTitle="Add data">
      <p className="description">
        Some text fields are missing data. Please fill them out before document can be rendered.
      </p>
      <form onSubmit={handleSubmit(handlePreviewModalSubmit)}>
        {fieldsInserted?.map(field => (
          <Input
            key={field.key}
            ref={register({ required: 'Required' })}
            name={field.name}
            label={field.label}
            error={errors[field.name]?.message}
            placeholder="Enter"
          />
        ))}
        <Button onClick={onClose} type="button" secondary>
          Back to edit document
        </Button>
        <Button type="submit">Proceed to Render</Button>
      </form>
    </StyledModal>
  );
};

export default FCPreviewModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }

  .modal-body {
    .input,
    .button {
      width: 100%;
      margin-top: 16px;
    }

    .description {
      font-size: 14px;
      line-height: 24px;
    }

    .button {
      height: 40px;
    }
  }
`;
