import React, { useContext } from 'react';

import { styled } from 'styles';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { FCBrowserContext } from './FCBrowserWrapper';

const DeleteFormModal = ({ ...props }) => {
  const { setOpenDeleteModal, setOpenDeleteFolderWarning, deleteForm, nodePopupData } = useContext(
    FCBrowserContext
  );
  const nodeType = nodePopupData?.data?.type;

  const handleCancel = () => setOpenDeleteModal(false);

  const handleConfirm = () => {
    if (nodePopupData?.data?.children?.length) {
      setOpenDeleteModal(false);
      setOpenDeleteFolderWarning(true);
    } else {
      deleteForm();
    }
  };

  return (
    <StyledDeleteFormModal
      modalTitle={`Delete ${nodeType}`}
      onClose={handleCancel}
      {...props}
      data-cy="modal_delete">
      <p>Are you sure you want to delete this {nodeType}? This action can’t be undone.</p>
      <Button onClick={handleCancel} large className="cancel-btn" data-cy="button_cancel_delete">
        Cancel
      </Button>
      <Button onClick={handleConfirm} large data-cy="button_confirm_delete">
        Delete
      </Button>
    </StyledDeleteFormModal>
  );
};

export default React.memo(DeleteFormModal);

const StyledDeleteFormModal = styled(Modal)`
  .modal-content {
    min-width: 300px;
    width: 300px;
    font-size: 16px;
    line-height: 24px;
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .modal-body {
    p {
      font-size: 16px;
    }
  }

  .close-btn {
    padding: 0;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    border-radius: ${props => props.theme.misc.borderRadius};

    svg {
      width: 8px;
      height: 8px;
    }
  }

  button.large {
    width: 100%;
    margin-top: 16px;

    &.cancel-btn {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.pink};
    }
  }
`;
