import React from 'react';
import clsx from 'clsx';
import { styled } from 'styles';
import Icon from 'components/Icon';

const GoBackButton: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({
  className,
  onClick,
  ...props
}) => (
  <StyledGoBackButton className={clsx('go-back-button', className)} onClick={onClick} {...props}>
    <Icon name="arrow-simple" width={16} height={8} />
  </StyledGoBackButton>
);

export default React.memo(GoBackButton);

const StyledGoBackButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: ${props => props.theme.misc.borderRadius};
  border: none;

  &,
  &:active,
  &:hover,
  &:focus {
    background: ${props => props.theme.colors.seashell};
  }

  &:focus {
    outline: 0;
  }

  .icon {
    fill: black;
  }
`;
