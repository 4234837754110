import React, { useContext, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { styled } from 'styles';

import useLayout from 'hooks/useLayout';
import Icon from 'components/Icon';
import EmptyFolder from '../components/EmptyFolder';
import GoBackButton from '../components/GoBackButton';
import { TreeNode } from '../types';
import TreeItem from './TreeItem';
import { FCBrowserContext } from './FCBrowserWrapper';

const BrowserContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const {
    nodes,
    breadcrumbs,
    openFolder,
    selectedFolder,
    currentRootFolder,
    searchString: search,
    setNodePopupData,
    editingNode
  } = useContext(FCBrowserContext);
  const layout = useLayout();
  const isMobile = layout === 'mobile';

  const renderNode = (node: TreeNode, idx) => {
    const nodeProps =
      node.type === 'form'
        ? { href: `/form-creation/${node.id}`, alt: node.name }
        : { onClick: () => openFolder(node) };

    return (
      <TreeItem
        {...nodeProps}
        item={node}
        isMobile={isMobile}
        editing={editingNode?.id === node.id}
        key={idx}
        onClick={e =>
          setNodePopupData({
            data: node,
            target: e.target
          })
        }
        data-cy={`tree_item_${idx}`}
      />
    );
  };

  const renderBreadcrumb = node => (
    <span onClick={() => openFolder(node)} key={node.id}>
      {node.name}
    </span>
  );

  const getFolderIconName = (type?: string) => (type === 'favorites' ? 'star' : 'fc-document');

  const breadcrumbsComponent = useMemo(
    () =>
      breadcrumbs.length > 0 && (
        <StyledBreadcrumbs className="breadcrumbs">
          <Icon name={getFolderIconName(breadcrumbs[0].type) as any} width={16} height={14} />
          {breadcrumbs.map(renderBreadcrumb)}
        </StyledBreadcrumbs>
      ),
    // eslint-disable-next-line
    [breadcrumbs, selectedFolder]
  );

  const mainContent = useMemo(() => {
    const emptyText = search ? 'No results found.' : 'No templates.';

    if (!nodes || !nodes.length) return <EmptyFolder>{emptyText}</EmptyFolder>;

    return <div>{nodes?.map(renderNode)}</div>;
    // eslint-disable-next-line
  }, [nodes, editingNode]);

  const goBack = useCallback(() => {
    if (!breadcrumbs) return;

    const prevFolder = !isMobile ? breadcrumbs[breadcrumbs.length - 1] : undefined;
    openFolder(prevFolder);
  }, [breadcrumbs, openFolder, isMobile]);

  const searchLabel = nodes?.length === 1 ? '1 result' : `${nodes?.length} results`;

  return (
    <StyledBrowserContent className={clsx('content', { search })} data-cy="browser_content">
      <div className="header">
        {isMobile && currentRootFolder !== 0 && (
          <GoBackButton className="prev-button" onClick={goBack} />
        )}
        {breadcrumbsComponent}
        {search && !isMobile && <div className="search-counter">{searchLabel}</div>}
      </div>

      {mainContent}
    </StyledBrowserContent>
  );
};

export default React.memo(BrowserContent);

const StyledBrowserContent = styled.main`
  position: relative;
  flex-grow: 1;
  padding: 24px;

  &.search {
    padding: 24px 40px;
  }

  .search-counter {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px 10px;

    &.search {
      padding: 0 10px 24px 10px;
    }

    .header:not(:empty) {
      margin-bottom: 8px;
      display: grid;
      grid-template-columns: 32px auto;
      grid-template-areas: 'button nav';

      .breadcrumbs {
        margin-right: 32px;
        grid-area: nav;
        justify-content: center;
        word-break: break-all;
      }

      .prev-button {
        margin-right: 10px;
        grid-area: button;
        width: 32px;
        height: 32px;
      }
    }
  }
`;

const StyledBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;

  span {
    cursor: pointer;
    position: relative;

    &:not(:last-of-type) {
      &:after {
        content: '›';
        display: inline-block;
        margin: 0 7px;
      }
    }

    &:last-of-type {
      pointer-events: none;
    }
  }

  .icon {
    margin-right: 7px;
    fill: ${props => props.theme.colors.red};
    flex-shrink: 0;
  }
`;
