import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useLocation, navigate } from '@reach/router';

import notify from 'notify';
import { DropdownOption } from 'components/Dropdown';
import { useWarnIfContentNotSaved } from 'hooks/useWarnIfContentNotSaved';
import {
  getTextBlocks,
  getVariables,
  getForm,
  getFullFormFolderTree,
  saveForm,
  getTemplate,
  saveTextBlock,
  createTextBlocksFolder,
  createFormFolder,
  generatePdfAndSaveToProject,
  generatePDFpreview,
  getDocumentsProjects
} from './api';
import { useFCTconfig } from './FCTconfig';
import { mapDocuments } from './mapping';

import { useFCVariables } from './useFCVariables';
import {
  DocumentsProjects,
  Form,
  FormTree,
  PreviewData,
  Project,
  SaveToProjectData,
  Template,
  TextBlockApiData,
  TextBlocksFolder,
  VariableInserted,
  VariablesFolder
} from './types';

type SaveToProjectModalData = {
  signature: boolean;
};

interface FCTContextValues {
  document?: Form;
  project?: Project;
  uniqueId?: string;
  folders: FormTree;
  textBlocks: TextBlocksFolder[];
  variables: VariablesFolder[];
  projectsOptions: DropdownOption[];
  categoriesOptions: DropdownOption[];
  fetchTextBlocks: () => void;
  setFolders: React.Dispatch<React.SetStateAction<FormTree>>;
  setTextBlocks: React.Dispatch<React.SetStateAction<TextBlocksFolder[]>>;
  saveDocument: () => void;
  setDocument: React.Dispatch<React.SetStateAction<Form | undefined>>;
  // TextBlock
  addNewTextBlockFolder: (folderName: string) => void;
  showSaveTextBlockModal: boolean;
  setShowSaveTextBlockModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveTextBlock: (form: any) => void;
  // FormSaveAs
  showFormSaveAsModal: () => void;
  openFormSaveAs: boolean;
  handleFormSaveAs: (form: any) => void;
  closeFormSaveAs: () => void;
  addNewFormFolder: (folderName: string) => void;
  // PDFpreview
  showSelectPropertyModal: boolean;
  setShowSelectPropertyModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPreviewModal: boolean;
  setShowPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPreviewScreen: boolean;
  setShowPreviewScreen: React.Dispatch<React.SetStateAction<boolean>>;
  handlePreviewClick: () => void;
  showSaveToProjectModal: boolean;
  openSaveToProjectModal: (value: { signature: boolean }) => void;
  getPreviewContentAsPDF: () => Promise<PreviewData | undefined>;
  getDocumentsProjects?: () => Promise<DocumentsProjects>;
  showSignature: boolean;
  handleSaveToProject: (form: any) => void;
  handleNewFormClick: () => void;

  showFileMenu: boolean;
  setShowFileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectPropertySubmit: (form: any) => void;
  handlePreviewModalSubmit: (form: any) => void;

  saveToProjData?: SaveToProjectData;
  setShowSaveToProjectModal: React.Dispatch<React.SetStateAction<boolean>>;

  saveTextBlockAndRerenderTree: (textBlockData: TextBlockApiData) => Promise<void>;

  variablesInserted: VariableInserted[];
}

const FCTContext = React.createContext<FCTContextValues>({} as FCTContextValues);

const useQuery = (query: string) => {
  return new URLSearchParams(useLocation().search).get(query);
};

const FCTContextWrapper = ({ children }) => {
  const { setWarnIfContentNotSaved } = useWarnIfContentNotSaved();
  const { editorContext } = useFCTconfig();
  const {
    setEditorValue,
    editorContainsVariables,
    getTextBlockForSaving,
    getContentForSaving,
    getPreviewContentAsHtml,
    setValueChangeListener
  } = useContext(editorContext);
  const { id } = useParams();
  const {
    getPropertyVariableValuesAndAddToInserted,
    addValuesToVariablesFromForm,
    variablesInserted,
    setVariablesInserted
  } = useFCVariables();

  const templateParam = useQuery('template');
  const isTemplate = Boolean(templateParam);
  const showSignature = templateParam === '1';

  const [propertyID, setPropertyID] = useState<number | undefined>(Number(useQuery('prop_id')));
  const [document, setDocument] = useState<Form>();
  const [project, setProject] = useState<Project>();
  const [uniqueId, setUniqueId] = useState<string>();
  const [template, setTemplate] = useState<Template>();
  const [folders, setFolders] = useState<FormTree>([]);
  const [textBlocks, setTextBlocks] = useState<TextBlocksFolder[]>([]);
  const [openFormSaveAs, setOpenFormSaveAs] = useState<boolean>(false);
  const [showSaveTextBlockModal, setShowSaveTextBlockModal] = useState<boolean>(false);
  const [saveToProjectModalData, setSaveToProjectModalData] = useState<SaveToProjectModalData>();
  const [projectsOptions, setProjectsOptions] = useState<DropdownOption[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<DropdownOption[]>([]);
  const [variables, setVariables] = useState<VariablesFolder[]>([]);

  // *** Preview Modal state ***
  const [showSelectPropertyModal, setShowSelectPropertyModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showPreviewScreen, setShowPreviewScreen] = useState<boolean>(false);
  const [showSaveToProjectModal, setShowSaveToProjectModal] = useState<boolean>(false);
  const [contentAsHtml, setContentAsHtml] = useState<string>();
  const [showFileMenu, setShowFileMenu] = useState(false);

  useEffect(() => {
    setValueChangeListener(() => {
      setWarnIfContentNotSaved(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFormClick = () => {
    setShowFileMenu(false);
    setEditorValue(undefined);
    setTemplate(undefined);
    setProject(undefined);
    setPropertyID(undefined);
    navigate('/form-creation/new');
  };

  // *** PreviewScreen Modal BEGIN ***

  const handlePreviewClick = () => {
    setWarnIfContentNotSaved(true);
    setContentAsHtml(undefined);
    setVariablesInserted([]);
    if (!propertyID && editorContainsVariables()) setShowSelectPropertyModal(true);
    else handleShowPreviewScreen(propertyID);
  };

  const handleSelectPropertySubmit = async ({ property }) => {
    setPropertyID(property);
    const project = projectsOptions.find(item => item.value === property);
    if (project) setProject({ id: project.value });
    setShowSelectPropertyModal(false);
    await handleShowPreviewScreen(property);
  };

  const handleShowPreviewScreen = async propId => {
    if (propId) {
      const { isVariablesWithoutValues } = await getPropertyVariableValuesAndAddToInserted(propId);

      if (isVariablesWithoutValues) setShowPreviewModal(true);
      else setShowPreviewScreen(true);
    } else if (editorContainsVariables()) setShowPreviewModal(true);
    else setShowPreviewScreen(true);
  };

  const handlePreviewModalSubmit = form => {
    addValuesToVariablesFromForm(form);
    setShowPreviewModal(false);
    setShowPreviewScreen(true);
  };

  const getPreviewContentAsPDF = useCallback(async () => {
    const html = getPreviewContentAsHtml(variablesInserted);
    setContentAsHtml(html);
    setVariablesInserted([]);
    const pdfData = await generatePDFpreview({ data: html });
    return pdfData;
  }, [getPreviewContentAsHtml, setVariablesInserted, variablesInserted]);

  const openSaveToProjectModal = (value: SaveToProjectModalData) => {
    setShowSaveToProjectModal(true);
    setSaveToProjectModalData(value);
  };

  const handleSaveToProject = async form => {
    await generatePdfAndSaveToProject({
      ...form,
      data: contentAsHtml,
      uniqueId
    });
    setShowSaveToProjectModal(false);
    setShowPreviewScreen(false);
  };

  // *** PreviewScreen Modal END ***

  // *** TextBlock Modal ***

  const addNewTextBlockFolder = async name => {
    await createTextBlocksFolder({ name, parentId: 0 });
    fetchTextBlocks();
  };

  const addNewFormFolder = async name => {
    const newFolders = await createFormFolder({ name, parentId: 0 });
    setFolders(newFolders);
  };

  const handleSaveTextBlock = async form => {
    const textBlockToSave = getTextBlockForSaving();
    if (!textBlockToSave) return;
    await saveTextBlockAndRerenderTree({
      ...form,
      content: textBlockToSave
    });
    closeSaveTextBlock();
  };

  const saveTextBlockAndRerenderTree = async (textBlockData: TextBlockApiData) => {
    const data = await saveTextBlock(textBlockData);
    setTextBlocks(data);
  };

  const closeSaveTextBlock = () => {
    setShowSaveTextBlockModal(false);
  };

  // *** FormSaveAs Modal BEGIN ***

  const handleFormSaveAs = async form => {
    const data = getContentForSaving();
    const payload = { ...form, data };
    if (id) payload.unique_id = id;
    const newFormId = await saveForm(payload);
    setWarnIfContentNotSaved(false);
    setOpenFormSaveAs(false);
    navigate(`/form-creation/${newFormId}`);
  };

  const showFormSaveAsModal = () => {
    setOpenFormSaveAs(true);
  };
  const closeFormSaveAs = () => {
    setOpenFormSaveAs(false);
  };

  // *** FormSaveAs Modal END ***

  const saveDocument = async () => {
    if (!document) throw Error('document should exist during saving');
    try {
      const data = getContentForSaving();
      const documentData: Form = { ...document, data };
      await saveForm(documentData);
      notify('Document saved');
      setWarnIfContentNotSaved(false);
    } catch (err) {
      notify(err.message);
    }
  };

  const fetchTextBlocks = useCallback(async () => {
    try {
      const data = await getTextBlocks();
      setTextBlocks(data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      notify(err.message);
    }
  }, []);

  useEffect(() => {
    fetchTextBlocks();
  }, [fetchTextBlocks]);

  useEffect(() => {
    const fetchVariables = async () => {
      try {
        const data = await getVariables();
        setVariables(data);
      } catch (err) {
        notify(err.message);
      }
    };

    fetchVariables();
  }, []);

  useEffect(() => {
    const fetchForm = async (id: string) => {
      try {
        const document = await getForm(id);
        setDocument(document);
        setUniqueId(document.uniqueId);
        setEditorValue(document.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        notify('Form not found');
      }
    };

    const fetchTemplate = async (id: string) => {
      try {
        const data = await getTemplate(id);
        setTemplate(data);
        setEditorValue(data.form);
        setUniqueId(data.uniqueId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        notify('Template not found');
      }
    };

    if (id && id !== 'new' && isTemplate) fetchTemplate(id);
    else if (id && id !== 'new') fetchForm(id);
    else setDocument(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTemplate]);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const folders = await getFullFormFolderTree();
        setFolders(folders);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('fetchFolders error:', err);
        notify(err.message);
      }
    };

    fetchFolders();
  }, []);

  useEffect(() => {
    const fetchDocumentsProjects = async () => {
      try {
        const data = await getDocumentsProjects();

        const projectsOptions = data.projects.map(item => ({
          value: item.pid,
          text: item.propinfo
        }));

        const categoriesOptions = mapDocuments(data.documentnames);

        if (template) {
          const projectItem = data.projects.find(item => item.pid === template.propertyId);
          const documentItem = data.documentnames.find(item => item.doctype === template.docType);
          if (projectItem) {
            setProject({
              id: projectItem.pid,
              name: documentItem?.docname,
              address: projectItem.propinfo,
              docType: documentItem?.doctype
            });
          }
        }
        setProjectsOptions(projectsOptions);
        setCategoriesOptions(categoriesOptions);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('fetchDocumentsProjects error:', err);
        notify(err.message);
      }
    };

    if (!(isTemplate && !template)) fetchDocumentsProjects();
  }, [isTemplate, template]);

  const saveToProjData: SaveToProjectData = {
    name: document?.name,
    uniqueId: document?.uniqueId,
    html: contentAsHtml,
    signatures: saveToProjectModalData?.signature || false,
    project
  };

  const contextValues: FCTContextValues = {
    categoriesOptions,
    document,
    project,
    folders,
    openFormSaveAs,
    projectsOptions,
    saveToProjData,
    showFileMenu,
    showSelectPropertyModal,
    showPreviewModal,
    showPreviewScreen,
    showSaveTextBlockModal,
    showSaveToProjectModal,
    showSignature,
    textBlocks,
    uniqueId,
    addNewFormFolder,
    addNewTextBlockFolder,
    closeFormSaveAs,
    fetchTextBlocks,
    getDocumentsProjects,
    getPreviewContentAsPDF,
    handleFormSaveAs,
    handleNewFormClick,
    handlePreviewClick,
    handlePreviewModalSubmit,
    handleSelectPropertySubmit,
    handleSaveTextBlock,
    handleSaveToProject,
    openSaveToProjectModal,
    saveDocument,
    setDocument,
    setFolders,
    setTextBlocks,
    setShowFileMenu,
    setShowSelectPropertyModal,
    setShowPreviewModal,
    setShowPreviewScreen,
    setShowSaveTextBlockModal,
    setShowSaveToProjectModal,
    showFormSaveAsModal,
    saveTextBlockAndRerenderTree,
    variables,
    variablesInserted
  };

  return <FCTContext.Provider value={{ ...contextValues }}>{children}</FCTContext.Provider>;
};

export const FCTContextWrapped = ({ children }) => {
  const { editorContextWrapper: EditorContextWrapper } = useFCTconfig();
  return (
    <EditorContextWrapper>
      <FCTContextWrapper>{children}</FCTContextWrapper>
    </EditorContextWrapper>
  );
};

export default FCTContext;
