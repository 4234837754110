import React from 'react';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import { Form } from '../types';

interface FCPreviewLayoutProps {
  components: {
    saveWithSignatureButton?: React.ReactNode;
    saveToProjectButton: React.ReactNode;
    document: React.ReactNode;
  };
  document?: Form;
  documentName?: string;
  onReturnClick: () => void;
}

const FCPreviewLayout: React.FC<FCPreviewLayoutProps> = ({
  components,
  document,
  documentName,
  onReturnClick
}) => {
  const layout = useLayout();
  const title = documentName || 'New Form';

  switch (layout) {
    case 'mobile':
      return (
        <StyledLayoutMobile className="preview-document mobile styleguide-v2">
          <header>
            <ButtonGray className="return-button" onClick={onReturnClick} data-cy="button_return">
              ←
            </ButtonGray>
            <div className="document-info">
              <h3 className="document-info-name" data-cy="document_title">
                {title}
              </h3>
              <p className="document-info-address" data-cy="document_address">
                {document?.address}
              </p>
            </div>
          </header>
          <div className="save-buttons">
            {components.saveWithSignatureButton}
            {components.saveToProjectButton}
          </div>
          <StyledDocumentContainer className="document-container">
            {components.document}
          </StyledDocumentContainer>
        </StyledLayoutMobile>
      );
    case 'tablet':
      return (
        <StyledLayoutTablet className="preview-document tablet styleguide-v2">
          <header>
            <div className="document-info">
              <h3 className="document-info-name" data-cy="document_title">
                {title}
              </h3>
              <p className="document-info-address" data-cy="document_address">
                {document?.address}
              </p>
            </div>
            <div className="buttons">
              <ButtonPink onClick={onReturnClick} data-cy="button_return">
                ← Back
              </ButtonPink>
              {components.saveWithSignatureButton}
              {components.saveToProjectButton}
            </div>
          </header>
          <StyledDocumentContainer className="document-container">
            {components.document}
          </StyledDocumentContainer>
        </StyledLayoutTablet>
      );
    default:
      return (
        <StyledLayoutDesktop className="preview-document desktop styleguide-v2">
          <header>
            <div className="header-section">
              <ButtonPink onClick={onReturnClick} data-cy="button_return">
                ← Back
              </ButtonPink>
            </div>
            <div className="header-section document-info">
              <h3 className="document-info-name" data-cy="document_title">
                {title}
              </h3>
              <p className="document-info-address" data-cy="document_address">
                {document?.address}
              </p>
            </div>
            <div className="header-section save-buttons">
              {components.saveWithSignatureButton}
              {components.saveToProjectButton}
            </div>
          </header>

          <StyledDocumentContainer className="document-container">
            {components.document}
          </StyledDocumentContainer>
        </StyledLayoutDesktop>
      );
  }
};

export default FCPreviewLayout;

const StyledDocumentContainer = styled.div`
  width: 100%;
  min-height: 100%;

  .react-pdf__Document {
    margin: 0 auto;

    .react-pdf__Page {
      margin-bottom: 20px;

      .react-pdf__Page__svg {
        width: auto !important;
        height: auto !important;
        max-width: 820px;
        margin: 0 auto;

        svg {
          width: 100%;
          height: auto;
          tspan {
            white-space: inherit;
          }
        }
      }

      .react-pdf__Page__textContent {
        display: none;
      }
    }
  }

  .react-pdf__message {
    color: white;
    text-align: center;
    height: 100%;
  }
`;

const StyledLayoutMobile = styled.div`
  header {
    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid ${props => props.theme.colors.grayDark};
    margin: 0 10px;
    padding: 0 3px;

    .return-button {
      margin-right: -32px;
    }

    .document-info {
      margin: auto;
      max-width: 50%;

      .document-info-name,
      .document-info-address {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .document-info-name {
        font-size: 12px;
        line-height: 16px;
      }

      .document-info-address {
        font-size: 12px;
        line-height: 16px;
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }

  .save-buttons {
    display: flex;
    padding: 12px;

    button {
      width: 100%;
      white-space: nowrap;

      &:nth-of-type(2) {
        margin-left: 8px;
      }
    }
  }

  .document-container {
    padding: 10px;
  }
`;

const StyledLayoutTablet = styled.div`
  height: 100%;

  header {
    height: 125px;
    padding: 16px 24px;

    .document-info {
      height: 44px;
      text-align: center;
      margin-bottom: 18px;

      .document-info-name {
        font-size: 16px;
        line-height: 24px;
      }

      .document-info-address {
        font-size: 12px;
        line-height: 16px;
        color: ${props => props.theme.colors.grayDark};
      }
    }

    .buttons {
      display: flex;

      button {
        margin-left: 8px;
        white-space: nowrap;

        &:nth-of-type(1) {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }

  .document-container {
    background: #525659;
    padding: 25px 18px;
  }
`;

const StyledLayoutDesktop = styled.div`
  height: 100%;
  overflow: auto;

  header {
    height: 80px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .button {
      white-space: nowrap;
    }

    .header-section {
      width: 100%;
    }

    .document-info {
      text-align: center;

      .document-info-name {
        font-size: 16px;
        line-height: 24px;
        margin: 0 20px;
      }

      .document-info-address {
        font-size: 12px;
        line-height: 16px;
        color: ${props => props.theme.colors.grayDark};
      }
    }

    .save-buttons {
      text-align: right;
      white-space: nowrap;

      .button {
        flex-shrink: 0;

        &:first-of-type {
          margin-right: 8px;
        }
      }
    }
  }

  .document-container {
    background: #525659;
    padding: 20px;
  }
`;

const ButtonPink = styled(Button)`
  background: ${props => props.theme.colors.pink};
  color: ${props => props.theme.colors.red};
  fill: ${props => props.theme.colors.red};
`;

const ButtonGray = styled(Button)`
  background: ${props => props.theme.colors.seashell};
  color: black;
  fill: black;
`;
