import useLayout from 'hooks/useLayout';
import notify from 'notify';
import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import {
  getFullFormFolderTree,
  getFavoriteDocuments,
  createFormFolder,
  saveForm,
  renameFolder,
  deleteForm,
  deleteFolder,
  deleteFolderWithForms
} from '../api';
import { TreeNode } from '../types';

type nodePopupDataProps = {
  data?: TreeNode;
  target?: EventTarget | HTMLElement;
};

export interface ContextValues {
  selectedFolder?: TreeNode;
  currentRootFolder?: number;
  nodes?: TreeNode[];
  folders?: TreeNode[];
  sidebarItems?: TreeNode[];
  mobileTab: React.ReactText;
  searchString: string;
  breadcrumbs: TreeNode[];
  openFolder: (value?: TreeNode) => void;
  search: React.Dispatch<React.SetStateAction<string>>;
  toggleMobileTab: (value: React.ReactText) => void;
  createFolder: (value: string) => void;
  nodePopupData?: nodePopupDataProps;
  setNodePopupData: React.Dispatch<React.SetStateAction<nodePopupDataProps | undefined>>;
  openDeleteModal: boolean;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteFolderWarning: boolean;
  setOpenDeleteFolderWarning: React.Dispatch<React.SetStateAction<boolean>>;
  editingNode?: TreeNode;
  setEditingNode: React.Dispatch<React.SetStateAction<TreeNode | undefined>>;
  renameNode: (value?: string) => void;
  deleteForm: () => void;
  isMobile: boolean;
}

enum MobileBrowserTabs {
  MostUsed = 1,
  Saved = 2
}

export const FCBrowserContext = createContext<ContextValues>({} as ContextValues);

export const filterBySearchString = (list: Array<TreeNode>, searchString: string) => {
  const lowerCaseSearchString = searchString.toLowerCase();

  return list.filter(item => item.name.toLowerCase().includes(lowerCaseSearchString));
};

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const FCBrowserWrapper = ({ children }) => {
  const [selectedFolder, setSelectedFolder] = useState<TreeNode>();
  const [nodes, setNodes] = useState<TreeNode[]>();
  const [mobileTab, setMobileTab] = useState(MobileBrowserTabs.MostUsed);
  const layout = useLayout();
  const isMobile = layout === 'mobile';
  const [searchString, setSearchString] = useState('');
  const [debouncedSearchString] = useDebounce(searchString, 200);
  const [folders, setFolders] = useState<TreeNode[]>([]);
  const [favorites, setFavorites] = useState<TreeNode[]>([]);
  const [nodePopupData, setNodePopupData] = useState<nodePopupDataProps>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDeleteFolderWarning, setOpenDeleteFolderWarning] = useState<boolean>(false);
  const [editingNode, setEditingNode] = useState<TreeNode | undefined>();

  const favoriteFolder: TreeNode = useMemo(
    () => ({
      id: 0,
      name: 'Most used',
      parentId: 0,
      type: 'favorite',
      children: favorites,
      isFolder: true
    }),
    [favorites]
  );

  const forms = useMemo(() => {
    return folders
      .map(folder => folder.children)
      .flat()
      .filter(child => child?.type === 'form');
  }, [folders]);

  const tree = useMemo(() => {
    const root: TreeNode[] = [favoriteFolder];
    const rootData = [...folders];

    rootData.forEach(node => {
      if (!node.parentId) return root.push(node);

      const parentIndex = rootData.findIndex(el => el.id === node.parentId);
      const parentFolder = rootData[parentIndex];

      if (!parentFolder.children) {
        rootData[parentIndex].children = [node];
        return;
      }

      parentFolder.children.push(node);
    });

    return root;
  }, [folders, favoriteFolder]);

  const currentRootFolder = useMemo(() => {
    if (selectedFolder && !selectedFolder.parentId) return selectedFolder.id;

    return folders.reduce((accum: TreeNode, current: TreeNode) => {
      return current.id === selectedFolder?.parentId ? { ...current } : accum;
    }, {} as TreeNode).id;
  }, [selectedFolder, folders]);

  const loadFolders = useCallback(async () => {
    try {
      const data = await getFullFormFolderTree();

      setFolders(data);
    } catch (err) {
      notify('Error fetching full tree data');
    }
  }, []);

  const loadFavorites = useCallback(async () => {
    try {
      const data = await getFavoriteDocuments();

      setFavorites(data);
    } catch (err) {
      notify('Error fetching favorite documents data');
    }
  }, []);

  // fetch folders and favorites on initial load
  useEffect(() => {
    loadFolders();
    loadFavorites();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let nodes: TreeNode[] = [];
    if (debouncedSearchString) {
      nodes = filterBySearchString(forms as TreeNode[], debouncedSearchString);
    } else if (!debouncedSearchString && selectedFolder?.id === 0) {
      nodes = [...favorites];
    } else if (selectedFolder?.children) {
      nodes = forms.filter(form => form?.parentId === selectedFolder.id) as TreeNode[];
    }
    setNodes(nodes.sort((a, b) => (a.name < b.name ? -1 : 1)));
  }, [debouncedSearchString, selectedFolder, favorites, forms]);

  // clear search input if user has selected a folder
  useEffect(() => {
    if (selectedFolder) setSearchString('');
  }, [selectedFolder]);

  // clear folder selection if search string is pesent
  useEffect(() => {
    if (searchString) setSelectedFolder(undefined);
    else setSelectedFolder(favoriteFolder);
  }, [searchString, favoriteFolder]);

  useEffect(() => {
    if (selectedFolder?.id)
      setSelectedFolder(folders.find(folder => folder.id === selectedFolder?.id));
    // eslint-disable-next-line
  }, [folders]);

  // Handle click on folder item, either root or subfolder
  const openFolder = clickedFolder => setSelectedFolder(clickedFolder);

  const handleCreateFolder = async (newFolder: string) => {
    try {
      await createFormFolder({ name: newFolder, parentId: 0 });
      notify('New folder created successfully');
    } catch (err) {
      notify('Error while creating a new folder');
    }

    loadFolders();
  };

  const renameNode = async (value = '') => {
    if (!editingNode) return;

    const { isFolder } = editingNode;
    const nodeType = isFolder ? 'folder' : 'form';

    try {
      if (isFolder) {
        const data = await renameFolder(editingNode.id, value);
        setFolders(data);
      } else {
        await saveForm({
          id: editingNode.id,
          name: value,
          folder: editingNode.parentId
        });
        loadFolders();
      }

      loadFavorites();

      notify(`${capitalize(nodeType)} edited successfully`);
    } catch (err) {
      notify(`Error while editing the ${nodeType}`);
    }

    setEditingNode(undefined);
  };

  const handleDeleteNode = async () => {
    const node = nodePopupData?.data;
    const isFolder = node?.isFolder;
    const nodeType = isFolder ? 'folder' : 'form';

    if (!node) return;

    try {
      let data: TreeNode[];
      if (!isFolder) data = await deleteForm(node?.id);
      else if (node?.children?.length)
        data = await deleteFolderWithForms(
          node?.id,
          node?.children.map(node => node.id)
        );
      else data = await deleteFolder(node?.id);

      setFolders(data);
      loadFavorites();

      notify(`${capitalize(nodeType)} deleted successfully`);
    } catch (err) {
      notify(`Error while deleting the ${nodeType}`);
    }

    if (openDeleteModal) setOpenDeleteModal(false);
    if (openDeleteFolderWarning) setOpenDeleteFolderWarning(false);
  };

  const toggleMobileTab = key => setMobileTab(key);

  const mobileFolders = useMemo(() => tree.filter(folder => folder.id), [tree]);

  const sidebarItems = useMemo(() => {
    if (layout !== 'mobile') {
      return tree;
    }
    if (mobileTab === MobileBrowserTabs.MostUsed) {
      return favoriteFolder.children;
    }
    return mobileFolders;
  }, [layout, tree, mobileTab, favoriteFolder, mobileFolders]);

  const contextValues = {
    selectedFolder,
    currentRootFolder,
    folders: tree,
    sidebarItems,
    nodes,
    mobileTab,
    openFolder,
    toggleMobileTab,
    createFolder: handleCreateFolder,
    search: setSearchString,
    searchString,
    breadcrumbs: selectedFolder ? [selectedFolder] : ([] as TreeNode[]),
    nodePopupData,
    setNodePopupData,
    openDeleteModal,
    setOpenDeleteModal,
    openDeleteFolderWarning,
    setOpenDeleteFolderWarning,
    renameNode,
    deleteForm: handleDeleteNode,
    editingNode,
    setEditingNode,
    isMobile
  };

  return <FCBrowserContext.Provider value={contextValues}>{children}</FCBrowserContext.Provider>;
};

export default FCBrowserWrapper;
