import React, { useRef, useEffect } from 'react';

export const useWarnIfContentNotSaved = () => {
  const warnIfContentNotSaved = useRef<boolean>(false);

  const setWarnIfContentNotSaved = React.useCallback((value: boolean) => {
    warnIfContentNotSaved.current = value;
  }, []);

  useEffect(() => {
    const handleWindowClose = e => {
      if (!warnIfContentNotSaved.current) return;
      e.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (e.returnValue =
        'If you close window your changes will be lost. Do you want to save them?');
    };
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  return { setWarnIfContentNotSaved };
};
