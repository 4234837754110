/* eslint-disable import/no-named-as-default */
import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Search from 'components/Search';
import { TextBlocksFolder } from '../../types';
import EmptyFolder from '../../components/EmptyFolder';
import GoBackButton from '../../components/GoBackButton';
import InputRename from '../../components/InputRename';
import FCSidebarContext from '../FCSidebarContext';
import DropdownMenu from './DropdownMenu';
import FoldersListItem from './FoldersListItem';
import TextBlockItem from './TextBlockItem';
import VariableItem from './VariableItem';

const dropdownOptions = [
  { value: 'rename', text: 'Rename folder' },
  { value: 'delete', text: 'Delete folder' }
];

interface FCSidebarRightProps extends React.HTMLAttributes<HTMLDivElement> {
  showCloseButton?: boolean;
}

const FCSidebarRight: React.FC<FCSidebarRightProps> = ({ showCloseButton = true }) => {
  const {
    currentFolder,
    currentSelection,
    showVariables,
    openFolder,
    closeFolder,
    handleFolderClick,
    handleSelection,
    updateTextBlocksFolder,
    setDeleteConfirm,
    isMobile
  } = useContext(FCSidebarContext);
  const [renaming, setRenaming] = useState(false);
  const [rename, setRename] = useState('');
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState('');
  const isEmpty = !currentFolder?.folders?.length && !currentFolder?.children.length;
  const emptyText = showVariables ? 'No variables.' : 'No text blocks.';

  let items = currentFolder?.children;
  if (searching && search && items)
    items = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));

  const handleDropdownMenuOptionClick = e => {
    const { value } = e.target;

    switch (value) {
      case 'rename':
        setRenaming(true);
        setRename(currentFolder.name);
        break;
      case 'delete':
        setDeleteConfirm({ id: currentFolder.id, type: 'folder' });
        break;
      default:
        break;
    }
  };

  const submitRenameFolder = () => {
    setRenaming(false);
    updateTextBlocksFolder({ ...currentFolder, name: rename });
  };

  useEffect(() => {
    setRenaming(false);
  }, [currentFolder]);

  const showSettings = currentFolder?.type === 'block';

  return (
    <StyledFolderContent className="folder-content">
      <div className="folder-header">
        {isMobile && (
          <GoBackButton
            onClick={() => handleFolderClick(currentFolder?.id)}
            data-cy="button_go_back"
          />
        )}
        <Icon name="folder-outline" size={16} />
        {renaming ? (
          <>
            <InputRename
              value={rename}
              onChange={e => setRename(e.target.value)}
              onEnter={submitRenameFolder}
              className="folder-title"
              data-cy="input_rename"
            />
            <Button
              onClick={submitRenameFolder}
              className="rename-save-button"
              data-cy="button_save">
              Save
            </Button>
            <Button
              onClick={() => setRenaming(false)}
              className="rename-cancel-button"
              secondary
              data-cy="button_cancel">
              Cancel
            </Button>
          </>
        ) : (
          <>
            <h4 className="folder-title">{currentFolder?.name}</h4>
            {!showVariables && (
              <Button
                onClick={() => setSearching(v => !v)}
                className={clsx('button-search', { active: searching })}
                simple
                data-cy="button_search">
                <Icon name="search" />
              </Button>
            )}
            {showSettings && (
              <DropdownMenu
                options={dropdownOptions}
                onOptionClick={handleDropdownMenuOptionClick}
                target={targetProps => (
                  <Button
                    className="button-settings"
                    simple
                    {...targetProps}
                    data-cy="button_settings">
                    <Icon name="gear" />
                  </Button>
                )}
                data-cy="dropdown_options"
              />
            )}
            {showCloseButton && (
              <Button onClick={closeFolder} className="button-close" data-cy="button_close_folder">
                <Icon name="close" size={8} />
              </Button>
            )}
          </>
        )}
      </div>
      {searching && (
        <div className="seach-container">
          <Search
            value={search}
            onChange={e => setSearch(e.target.value)}
            clear={() => setSearch('')}
            placeholder="Search"
            data-cy="input_search"
          />
        </div>
      )}
      {isEmpty ? (
        <EmptyFolder>{emptyText}</EmptyFolder>
      ) : (
        <div className="text-blocks scrollable-fct">
          {!showVariables &&
            currentFolder?.folders?.map((item: TextBlocksFolder) => (
              <FoldersListItem
                key={item.id}
                data-key={item.id}
                name={item.name}
                hasNodes={!!item.children.length || !!item.folders?.length}
                onClick={() => handleFolderClick(item.id)}
                className={clsx('folder', { open: openFolder === item.id })}
                data-cy={`folder_${item.id}`}
              />
            ))}
          {items.map(item =>
            showVariables ? (
              <VariableItem
                item={item}
                onClick={() => handleSelection(item)}
                key={item.id}
                active={currentSelection === item}
                data-cy={`variable_${item.id}`}
              />
            ) : (
              <TextBlockItem
                item={item}
                onClick={() => handleSelection(item)}
                key={item.id}
                active={currentSelection === item}
                data-cy={`text_block_${item.id}`}
              />
            )
          )}
          {search && items.length === 0 && (
            <div className="no-results" data-cy="no_results">
              No results
            </div>
          )}
        </div>
      )}
    </StyledFolderContent>
  );
};

export default FCSidebarRight;

const StyledFolderContent = styled.div`
  flex-shrink: 0;
  flex: 1;

  .folder-header {
    display: flex;
    align-items: baseline;
    padding: 4px 0 20px 0;
    word-break: break-word;

    .icon-folder-outline {
      flex-shrink: 0;
      position: relative;
      top: 4px;
      margin-right: 8px;
      flex-shrink: 0;
      fill: ${props => props.theme.colors.red};
    }

    .folder-title {
      font-size: 14px;
      line-height: 24px;
      margin-right: auto;
    }

    .button-search,
    .button-settings {
      fill: #c4c4c4;
      margin-left: 16px;
    }

    .button-search.active {
      fill: #000;
    }

    .button-close {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      padding: 0;
      margin-left: 16px;
    }

    .rename-save-button,
    .rename-cancel-button {
      height: 24px;
      margin-left: 8px;
      flex-shrink: 0;
    }
  }

  .seach-container {
    padding: 0 20px;
    margin: 0 0 16px 0;

    .input {
      width: 100%;

      input {
        height: 40px;
        padding-left: 28px;
      }
    }
  }

  .text-blocks {
    padding: 0 0 20px;
    overflow-y: auto;
    flex: 1;
    margin-right: 8px;

    .folder {
      margin-left: 0;
      margin-bottom: 8px;
      padding-left: 0;
    }

    .no-results {
      font-size: 12px;
      line-height: 16px;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0 0 60px;
      margin-right: 0;
    }
    @media (width: 768px) {
      margin-right: 0;
    }
  }
`;
