import { useState, useContext } from 'react';

import { getVariablesValues } from './api';
import { VariableValue, VariableInserted } from './types';
import { useFCTconfig } from './FCTconfig';

export const getVariablesWithoutValues = (variablesInserted: VariableInserted[]) =>
  variablesInserted.filter(v => !v.value);

export const useFCVariables = () => {
  const { editorContext } = useFCTconfig();
  const { getVariablesInserted } = useContext(editorContext);
  const [variablesInserted, setVariablesInserted] = useState<VariableInserted[]>([]);

  const addValuesToVariables = async values => {
    const lValues: VariableValue[] = values;

    // create Map to simplify searching variable value by name
    const variablesValuesMap = new Map(lValues.map(({ name, value }) => [name, value]));

    const varsInserted = getVariablesInserted();

    // add values to variables
    const formVariablesWithValues = varsInserted.map(v => {
      const value = variablesValuesMap.get(v.name);
      return { ...v, value };
    });
    return formVariablesWithValues;
  };

  const getPropertyVariableValuesAndAddToInserted = async propertyId => {
    const propertyValues = await getVariablesValues(propertyId);
    const formVariablesWithValues = await addValuesToVariables(propertyValues);
    const variablesWithoutValues = getVariablesWithoutValues(formVariablesWithValues);
    const isVariablesWithoutValues: boolean = variablesWithoutValues.length > 0;

    setVariablesInserted(formVariablesWithValues);

    return { isVariablesWithoutValues };
  };

  const addValuesToVariablesFromForm = form => {
    setVariablesInserted(items =>
      items.map(item => ({ ...item, value: form[item.name] || item.value }))
    );
  };

  return {
    getPropertyVariableValuesAndAddToInserted,
    addValuesToVariablesFromForm,
    variablesInserted,
    setVariablesInserted
  };
};
