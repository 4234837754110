import React, { useContext } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import FCSidebarLeft from './components/FCSidebarLeft';
import FCSidebarRight from './components/FCSidebarRight';
import ConfirmationDialog from 'components/ConfirmationDialog';
import FCSidebarContext, { FCSidebarWrapper } from './FCSidebarContext';

interface FCSidebarViewProps extends React.HTMLAttributes<HTMLDivElement> {
  buttons?: React.ReactNode[];
  onClose?: () => void;
}

const FCSidebarView: React.FC<FCSidebarViewProps> = ({ buttons }) => {
  const layout = useLayout();

  const {
    expand,
    handlePasteSelection,
    currentSelection,
    deleteConfirm,
    setDeleteConfirm,
    deleteItem
  } = useContext(FCSidebarContext);

  const renderButtons = (): JSX.Element | null => {
    if (buttons) return <div className="sidebar-buttons">{buttons}</div>;

    return null;
  };

  const mobileLayout = expand ? (
    <>
      <FCSidebarRight showCloseButton={false} />
      {currentSelection && (
        <div className="paste-button">
          <Button onClick={handlePasteSelection}>Paste in document</Button>
        </div>
      )}
    </>
  ) : (
    <>
      <h2 className="side-menu-title">Add to document</h2>
      <FCSidebarLeft />
      {renderButtons()}
    </>
  );

  const tabletLayout = expand ? (
    <>
      <FCSidebarLeft />
      <FCSidebarRight showCloseButton />
      {currentSelection && (
        <Button onClick={handlePasteSelection} className="paste-button">
          Paste in document
        </Button>
      )}
    </>
  ) : (
    <>
      <FCSidebarLeft />
    </>
  );

  const desktopLayout = (
    <>
      <FCSidebarLeft />
      <FCSidebarRight />
    </>
  );

  const renderConfirmationDialogue = () => {
    const deletionType = deleteConfirm?.type === 'block' ? 'text block' : 'folder';

    return (
      <ConfirmationDialog
        modalTitle={`Delete ${deletionType}`}
        open={Boolean(deleteConfirm)}
        onSubmit={() => deleteItem(deleteConfirm!.id, deleteConfirm!.type)}
        onClose={() => setDeleteConfirm(null)}>
        <p>Are you sure you want to delete this {deletionType}? This action can't be undone.</p>
      </ConfirmationDialog>
    );
  };

  const getLayout = () => {
    switch (layout) {
      case 'mobile':
        return mobileLayout;
      case 'tablet':
        return tabletLayout;
      default:
        return desktopLayout;
    }
  };

  return (
    <StyledFCSidebar className={clsx('sidebar', { expand })}>
      {getLayout()}
      {renderConfirmationDialogue()}
    </StyledFCSidebar>
  );
};

const FCSidebar: React.FC<FCSidebarViewProps> = ({ onClose, ...props }) => (
  <FCSidebarWrapper onClose={onClose}>
    <FCSidebarView {...props} />
  </FCSidebarWrapper>
);

export default FCSidebar;

const StyledFCSidebar = styled.div`
  z-index: 10;

  &.expand {
    width: auto;
  }

  .accordion.open .accordion-head {
    padding-bottom: 12px;
  }

  .paste-button {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background: white;

    button {
      height: 40px;
      width: 100%;
    }
  }

  .paste-item {
    position: absolute;
    right: 0;
    bottom: -39px;
    z-index: 1000;

    button {
      padding: 0 24px;
    }
  }

  .sidebar-buttons {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background: #fff;

    .button-file {
      width: 103px;
      margin-right: 9px;
    }

    .button-preview {
      width: 100%;
    }
  }

  .folder-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    flex: 1;
    flex-flow: column;

    .folder-content {
      .folder-header {
        margin-right: 46px;

        .go-back-button {
          margin-right: 16px;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .folders {
      padding: 0 0 30px 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    width: 256px;

    .folders {
      width: 256px;
      padding: 0 0 0 30px;
      border-right: 1px solid ${props => props.theme.colors.seashell};
    }

    .folder-content {
      width: 390px;
      border-right: 1px solid ${props => props.theme.colors.seashell};

      .folder-header {
        padding: 20px;
      }

      .text-blocks {
        padding: 0 20px 20px 20px;
      }
    }
  }

  @media (width: 768px) {
    .folders {
      padding: 4px 0 10px 10px;
    }

    .folder-content {
      width: 512px;

      .folder-header {
        margin-right: 0;
      }
    }
  }
`;
