import Button from 'components/Button';
import Icon from 'components/Icon';
import React, { useRef, useContext, useEffect } from 'react';

import { styled } from 'styles';
import InputRename from '../components/InputRename';
import { FCBrowserContext } from './FCBrowserWrapper';

interface RenameFormProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: string;
}

const RenameForm: React.FC<RenameFormProps> = ({ value }) => {
  const { renameNode, setEditingNode } = useContext(FCBrowserContext);
  const input = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    input?.current?.select();
  }, []);

  const handleCancel = e => {
    disableDefaultBehavior(e);
    setEditingNode(undefined);
  };

  const handleConfirm = e => {
    if (!input.current?.value) {
      handleCancel(e);
      return;
    }

    disableDefaultBehavior(e);

    renameNode(input.current?.value);
  };

  const disableDefaultBehavior = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEnter = (value: string) => {
    renameNode(value);
  };

  return (
    <StyledRenameForm className="rename-form">
      <InputRename
        defaultValue={value}
        onClick={disableDefaultBehavior}
        ref={input}
        onEnter={handleEnter}
        data-cy="input_rename"
      />
      <div className="rename-form__buttons">
        <Button simple onClick={handleCancel} data-cy="button_rename_cancel">
          <Icon name="mini-x" />
        </Button>
        <Button simple onClick={handleConfirm} data-cy="button_rename_confirm">
          <Icon name="check" />
        </Button>
      </div>
    </StyledRenameForm>
  );
};

export default React.memo(RenameForm);

const StyledRenameForm = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  .icon {
    width: inherit;
    height: inherit;
    color: ${props => props.theme.colors.gray};
  }

  input {
    flex-grow: 1;
    line-height: 24px;
    background: transparent;
  }

  input::selection {
    color: #fff;
    background: #a7e1f9;
  }

  input::-moz-selection {
    color: #fff;
    background: #a7e1f9;
  }

  .rename-form__buttons {
    display: flex;
    margin-right: 5px;

    .button {
      margin-left: 11px;
    }

    .icon-mini-x {
      width: 8px;
      height: 8px;
      fill: ${props => props.theme.colors.grayDark};
    }

    .icon-check {
      width: 12px;
      height: 12px;
    }

    .icon-check {
      fill: ${props => props.theme.colors.red};
    }
  }
`;
