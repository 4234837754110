import React, { useState, useMemo, useContext } from 'react';
import clsx from 'clsx';
import truncate from 'lodash/truncate';

import { styled } from 'styles';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { TextBlock } from '../../types';
import InputRename from '../../components/InputRename';
import { useFCTconfig } from '../../FCTconfig';
import FCSidebarContext from '../FCSidebarContext';
import DropdownMenu from './DropdownMenu';
// import FCTContext from '../../FCTContext';

const MAX_LENGTH = 290;

interface TextBlockItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: TextBlock;
  active: boolean;
  'data-cy'?: string;
}

const dropdownOptions = [
  { value: 'rename', text: `Rename block` },
  { value: 'delete', text: `Delete block` }
];

const TextBlockItem: React.FC<TextBlockItemProps> = ({
  item,
  active,
  className,
  'data-cy': dataCY,
  ...props
}) => {
  const { editorContext } = useFCTconfig();

  const { transformFragmentToString } = useContext(editorContext);
  const {
    setDeleteConfirm,
    updateTextBlock,
    isTablet,
    isMobile,
    handlePasteSelection,
    currentSelection
  } = useContext(FCSidebarContext);
  const [renaming, setRenaming] = useState(false);
  const [rename, setRename] = useState('');

  const [expand, setExpand] = useState<boolean>(false);

  const handleExpand = e => {
    e.stopPropagation();
    setExpand(value => !value);
  };

  const handleDropdownMenuOptionClick = e => {
    const { value } = e.target;

    switch (value) {
      case 'rename':
        setRenaming(true);
        setRename(item.name);
        break;
      case 'delete':
        setDeleteConfirm({ id: item.id, type: 'block' });
        break;
      default:
        break;
    }
  };

  const submitRenameFolder = () => {
    setRenaming(false);
    updateTextBlock({ ...item, name: rename });
  };

  const contentText: string | undefined = useMemo(
    // () => item.children.map(child => child.children.map(gc => gc.text)).join(''),
    () => transformFragmentToString(item.data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  const displaySeeMore = contentText && contentText.length > MAX_LENGTH;

  const content = useMemo(() => {
    if (!displaySeeMore || expand) return contentText;
    return truncate(contentText, { length: MAX_LENGTH });
  }, [contentText, expand, displaySeeMore]);

  const pasteButton =
    isTablet && !isMobile && currentSelection === item ? (
      <div className="paste-item">
        <Button onClick={handlePasteSelection} data-cy="button_paste_in_document">
          Paste in document
        </Button>
      </div>
    ) : null;

  return (
    <StyledTextBlockItem
      className={clsx('text-block', { active }, className)}
      data-cy={dataCY}
      {...props}>
      <div className="header">
        {renaming ? (
          <>
            <InputRename
              value={rename}
              onChange={e => setRename(e.target.value)}
              onClick={e => e.stopPropagation()}
              onEnter={submitRenameFolder}
              className="name"
              data-cy="input_rename"
            />

            <Button
              onClick={e => {
                e.stopPropagation();
                submitRenameFolder();
              }}
              className="rename-save-button"
              data-cy="button_save">
              Save
            </Button>
            <Button
              onClick={e => {
                e.stopPropagation();
                setRenaming(false);
              }}
              className="rename-cancel-button"
              secondary
              data-cy="button_cancel">
              Cancel
            </Button>
          </>
        ) : (
          <>
            <h5 className="name">{item.name}</h5>
            <DropdownMenu
              options={dropdownOptions}
              onOptionClick={handleDropdownMenuOptionClick}
              className="menu"
              data-cy="text_block_menu"
              target={targetProps => (
                <Button simple {...targetProps} data-cy="button_text_block_menu">
                  <Icon name="menu-dots" size={16} />
                </Button>
              )}
            />
          </>
        )}
      </div>
      <div className={clsx('description', { expand, 'see-more': displaySeeMore })}>
        {content}{' '}
        {displaySeeMore && (
          <button
            className="collapse"
            onClick={handleExpand}
            data-cy={expand ? 'button_collapse' : 'button_expand'}>
            {expand ? `See less` : `See more`}
          </button>
        )}
      </div>
      {pasteButton}
    </StyledTextBlockItem>
  );
};

export default TextBlockItem;

export const StyledTextBlockItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid ${props => props.theme.colors.seashell};
  border-radius: ${props => props.theme.misc.borderRadius};
  padding: 8px;
  margin-bottom: 8px;
  word-break: break-word;
  clear: both;
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    &:hover {
      border-color: #000000;
    }
  }

  &.active {
    border-color: #000000;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .header {
    display: flex;
    min-height: 20px;
    margin-bottom: 6px;

    .name {
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
    }

    .menu {
      margin-left: auto;

      button {
        fill: ${props => props.theme.colors.gray};
      }
    }

    .rename-save-button,
    .rename-cancel-button {
      margin-left: 8px;
      fill: ${props => props.theme.colors.gray};
      height: 24px;
      flex-shrink: 0;
    }
  }

  .description.see-more {
    float: left;
  }

  .collapse {
    color: ${props => props.theme.colors.red};
    margin-right: 5px;
    float: right;
    border: none;
    background: none;
    z-index: 1;
    outline: none;
    cursor: pointer;
  }
`;
