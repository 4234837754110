import React, { useContext, useRef } from 'react';
import { navigate } from '@reach/router';

import { styled } from 'styles';
import Icon from 'components/Icon';
import Button from 'components/Button';
import PopMenu from 'components/PopMenu';
import HeaderAvatarMenu from 'components/HeaderAvatarMenu';
import FCTContext, { FCTContextWrapped } from './FCTContext';
import FCEditDocumentLayout from './FCEditDocumentLayout';
import FCPreview from './FCPreview';
import FCSaveTextBlockModal from './FCModals/FCSaveTextBlockModal';
import FCSaveAsModal from './FCModals/FCSaveAsModal';
import FCSelectPropertyModal from './FCModals/FCSelectPropertyModal';
import FCPreviewModal from './FCModals/FCPreviewModal';
import { useFCTconfig } from './FCTconfig';
import TinyMCEContext from './editors/tinymce/TinyMCEContext';

const FileMenuOption = ({ children, ...props }) => {
  return (
    <li role="button" {...props}>
      {children}
    </li>
  );
};

const FCEditDocument = () => {
  const {
    document,
    project,
    showPreviewScreen,
    showFileMenu,
    saveDocument,
    showFormSaveAsModal,
    handlePreviewClick,
    handleNewFormClick,
    setShowFileMenu,
    setShowSaveTextBlockModal
  } = useContext(FCTContext);
  const { selection } = useContext(TinyMCEContext);
  const { editorName, editor: FCEditor } = useFCTconfig();
  const fileButtonRef = useRef(null);

  if (showPreviewScreen) {
    return <FCPreview />;
  }

  return (
    <>
      <FCEditDocumentLayout
        editorName={editorName}
        components={{
          doumentInfo: (
            <>
              <h3 className="title" data-cy="title">
                {document?.name || project?.name || 'New Form'}
              </h3>
              <p className="address" data-cy="address">
                {document?.address || project?.address}
              </p>
            </>
          ),
          avatarMenu: <HeaderAvatarMenu className="avatar-menu" />,
          saveAsTextBlockButton: Boolean(selection) && (
            <Button
              secondary
              onClick={() => setShowSaveTextBlockModal(true)}
              className="button-save-block"
              data-cy="button_save_as_text_block">
              + Save selection as Text Block
            </Button>
          ),
          fileButton: (
            <Button
              ref={fileButtonRef}
              onClick={() => setShowFileMenu(v => !v)}
              className="button-file"
              secondary
              data-cy="button_file">
              <Icon name="fc-file" /> File
            </Button>
          ),
          previewButton: (
            <Button
              onClick={() => handlePreviewClick()}
              className="button-preview"
              data-cy="button_preview">
              Preview & Render
            </Button>
          ),
          editor: <FCEditor />
        }}
      />
      <StyledPopMenu
        open={showFileMenu}
        placement="bottom-end"
        onOutsideClick={() => setShowFileMenu(false)}
        target={fileButtonRef.current}>
        <StyledFileMenu>
          <FileMenuOption
            onClick={() => navigate('/form-creation')}
            data-cy="option_back_to_templates">
            <Icon name="arrow-simple" />
            Back to Templates
          </FileMenuOption>
          <FileMenuOption onClick={handleNewFormClick} data-cy="option_new_form">
            <Icon name="fc-file" />
            New Form
          </FileMenuOption>
          {document?.id && (
            <FileMenuOption
              onClick={() => {
                setShowFileMenu(false);
                saveDocument();
              }}
              data-cy="option_save">
              <Icon name="fc-save" />
              Save
            </FileMenuOption>
          )}
          <FileMenuOption
            onClick={() => {
              setShowFileMenu(false);
              showFormSaveAsModal();
            }}
            data-cy="option_save_as">
            <Icon name="fc-save" />
            Save as
          </FileMenuOption>
        </StyledFileMenu>
      </StyledPopMenu>
      <FCSaveTextBlockModal />
      <FCSaveAsModal />
      <FCSelectPropertyModal />
      <FCPreviewModal />
    </>
  );
};

const EditDocument = () => (
  <FCTContextWrapped>
    <FCEditDocument />
  </FCTContextWrapped>
);

export default EditDocument;
// export default FCEditDocument;

const StyledFileMenu = styled.ul`
  list-style: none;
  padding: 16px 0;
  width: 240px;
  background: white;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);

  li {
    box-sizing: border-box;
    height: 33px;
    padding: 8px 18px;
    font-size: 12px;
    line-height: 18px;
    fill: ${props => props.theme.colors.grayDark};
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.seashell};
    }

    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
`;

const StyledPopMenu = styled(PopMenu)`
  padding: 0;
`;
