import React, { useContext, useState } from 'react';
import notify from 'notify';

import useLayout from 'hooks/useLayout';
import FCTContext from '../FCTContext';
import {
  createTextBlocksFolder,
  deleteTextBlocksFolder,
  deleteTextBlock as deleteTextBlockRequest,
  editTextBlocksFolder,
  deleteMultipleTextBlocks
} from '../api';
import { Variable, VariablesFolder, TextBlock, TreeNode } from '../types';
// import SlateContext from '../SlateContext';
import { useFCTconfig } from '../FCTconfig';

type SidebarItem = 'folder' | 'block';

type DeleteConfirmState = { id: number; type: SidebarItem } | null;

type SidebarContextValues = {
  createFolder: (value: string) => void;
  handleFolderClick: (value: number | string) => void;
  closeFolder: () => void;
  rootFolders: TreeNode[];
  variables: VariablesFolder[];
  showVariables?: boolean;
  expand?: boolean;
  openFolder: number | string | null;
  currentFolder?: any;
  currentSelection: Variable | TextBlock | null;
  isMobile?: boolean;
  isTablet?: boolean;
  updateTextBlock: (textBlock: TextBlock) => void;
  updateTextBlocksFolder: (folder: TreeNode) => void;
  handleSelection: (value: Variable | TextBlock) => void;
  handlePasteSelection: () => void;
  deleteItem: (id: number, type: SidebarItem) => void;
  deleteConfirm: DeleteConfirmState;
  setDeleteConfirm: React.Dispatch<React.SetStateAction<DeleteConfirmState>>;
};

const FCSidebarContext = React.createContext<SidebarContextValues>({} as SidebarContextValues);

const isTextBlock = (value: TextBlock | Variable): value is TextBlock =>
  (value as TextBlock).parentId !== undefined && (value as TextBlock).data !== undefined;

export const FCSidebarWrapper: React.FC<any> = ({ children, onClose }) => {
  const layout = useLayout();
  const { editorContext } = useFCTconfig();
  const {
    textBlocks: textBlockFolders,
    variables,
    saveTextBlockAndRerenderTree,
    setTextBlocks
  } = useContext(FCTContext);
  const { insertTextBlock, insertVariable } = useContext(editorContext);
  const [openFolder, setOpenFolder] = useState<number | string | null>(null);
  const [currentSelection, setCurrentSelection] = useState<Variable | TextBlock | null>(null);
  const [deleteConfirm, setDeleteConfirm] = useState<DeleteConfirmState>(null);
  const expand = Boolean(openFolder);

  const folders = [...textBlockFolders, ...variables];
  const currentFolder = folders.find(item => item.id === openFolder);
  const showVariables = currentFolder?.type === 'variable';
  const rootFolders = textBlockFolders.filter(folder => !folder.parentId);

  const closeFolder = () => setOpenFolder(null);

  const handleFolderClick = (id: number | string) => {
    if (openFolder === id) closeFolder();
    else setOpenFolder(id);

    setCurrentSelection(null);
  };

  const createFolder = async (folderName: string) => {
    try {
      const data = await createTextBlocksFolder({ name: folderName, parentId: 0 });
      setTextBlocks(data);

      notify('New folder created successfully');
    } catch (err) {
      notify('Error while creating a new folder');
    }
  };

  const deleteTextBlock = async (id: number) => {
    try {
      const data = await deleteTextBlockRequest(id);
      setTextBlocks(data);
      notify('Text block deleted successfully');
    } catch (err) {
      notify('Error while deleting text block');
    }
  };

  const deleteFolder = async () => {
    try {
      if (currentFolder?.children?.length) {
        await deleteMultipleTextBlocks((currentFolder.children as TreeNode[]).map(item => item.id));
      }
      const data = await deleteTextBlocksFolder(currentFolder?.id as number);
      setTextBlocks(data);
      notify('Folder deleted successfully');
      setOpenFolder(null);
    } catch (err) {
      notify('Error while deleting folder');
    }
  };

  const deleteItem = (id: number, type: SidebarItem) => {
    const requestHandler = type === 'block' ? deleteTextBlock : deleteFolder;
    requestHandler(id).then(() => setDeleteConfirm(null));
  };

  const handleSelection = (value: Variable | TextBlock) => {
    if (value === currentSelection) setCurrentSelection(null);
    else setCurrentSelection(value);

    if (layout !== 'desktop' || !value) return;

    if (isTextBlock(value)) insertTextBlock(value);
    else insertVariable(value);
  };

  const handlePasteSelection = () => {
    if (!currentSelection) return;
    if (isTextBlock(currentSelection)) insertTextBlock(currentSelection);
    else insertVariable(currentSelection);
    if (layout === 'mobile') {
      setOpenFolder(null);
      onClose();
    }
    setCurrentSelection(null);
  };

  const updateTextBlocksFolder = async (folder: TreeNode) => {
    try {
      const data = await editTextBlocksFolder(folder.id, folder.name);
      setTextBlocks(data);
      notify('Folder renamed successfully');
    } catch (err) {
      notify('Error while renaming folder');
    }
  };

  const updateTextBlock = async (textBlock: TextBlock) => {
    try {
      if (!textBlock) throw Error('No text block with given id');

      await saveTextBlockAndRerenderTree({
        name: String(textBlock.name),
        folder: textBlock.parentId,
        content: String(textBlock.data),
        id: textBlock.id
      });

      notify('Text block renamed successfully');
    } catch (err) {
      notify('Error while renaming text block');
    }
  };

  const contextValues: SidebarContextValues = {
    createFolder,
    handleFolderClick,
    closeFolder,
    rootFolders,
    variables,
    openFolder,
    currentFolder,
    deleteConfirm,
    setDeleteConfirm,
    showVariables,
    expand,
    currentSelection,
    handleSelection,
    handlePasteSelection,
    deleteItem,
    updateTextBlock,
    updateTextBlocksFolder
  };

  return <FCSidebarContext.Provider value={contextValues}>{children}</FCSidebarContext.Provider>;
};

export default FCSidebarContext;
