import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import useOutsideClick from 'hooks/useOutsideClick';
import { styled } from 'styles';

type Option = {
  value: string;
  text: React.ReactNode;
};

interface DropdownMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option[];
  target: (data: { onClick: (e: any) => void }) => JSX.Element;
  onOptionClick: (e: React.MouseEvent, value: string) => void;
  'data-cy'?: string;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  options,
  target,
  onOptionClick,
  className,
  'data-cy': dataCY,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  useOutsideClick(containerRef, () => {
    if (open) setOpen(false);
  });

  const handleOpen = e => {
    e.stopPropagation();
    setOpen(value => !value);
  };

  const handleOptionClick = (e: React.MouseEvent, value: string) => {
    e.stopPropagation();
    if (onOptionClick) onOptionClick(e, value);
    setOpen(false);
  };

  return (
    <StyledDropdown
      ref={containerRef}
      className={clsx('dropdown-menu', className)}
      data-cy={dataCY}
      {...props}>
      {target({ onClick: handleOpen })}
      {open && (
        <StyledDropdownOptions className="dropdown-options">
          {options.map(({ value, text }) => (
            <button
              key={value}
              value={value}
              onClick={e => handleOptionClick(e, value)}
              className="dropdown-option"
              data-cy={dataCY ? `${dataCY}_option_${value}` : undefined}>
              {text}
            </button>
          ))}
        </StyledDropdownOptions>
      )}
    </StyledDropdown>
  );
};

export default DropdownMenu;

const StyledDropdown = styled.div`
  position: relative;
`;

const StyledDropdownOptions = styled.div`
  position: absolute;
  right: 0;
  margin-top: 6px;
  min-width: 125px;
  padding: 8px 0 9px;
  background: white;
  border-radius: ${props => props.theme.misc.borderRadius};
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  z-index: 1;

  .dropdown-option {
    padding: 7px 30px 7px 12px;
    font-size: 12px;
    line-height: 18px;
    border: none;
    background: none;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: ${props => props.theme.colors.grayDark};
    }
  }
`;
