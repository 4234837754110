import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { styled } from 'styles';
import FCTContext from '../FCTContext';

const FCSelectPropertyModal = () => {
  const {
    projectsOptions,
    showSelectPropertyModal,
    setShowSelectPropertyModal,
    handleSelectPropertySubmit
  } = useContext(FCTContext);
  const { control, errors, handleSubmit, formState } = useForm({ mode: 'onChange' });
  const disabled = !formState.isValid || formState.isSubmitting || !formState.isDirty;

  return (
    <StyledModal
      open={showSelectPropertyModal}
      modalTitle="Select project"
      onClose={() => setShowSelectPropertyModal(false)}>
      <form onSubmit={handleSubmit(handleSelectPropertySubmit)}>
        <Controller
          control={control}
          name="property"
          defaultValue=""
          rules={{ required: 'Required' }}
          render={controllerProps => (
            <Dropdown
              search
              options={projectsOptions}
              label="Project"
              placeholder="Select"
              error={errors?.property?.type}
              data-cy="dropdown_property"
              {...controllerProps}
            />
          )}
        />
        <Button type="submit" disabled={disabled}>
          Preview
        </Button>
      </form>
    </StyledModal>
  );
};

export default FCSelectPropertyModal;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 324px;
  }

  .modal-body {
    .dropdown,
    .button {
      width: 100%;
    }

    .dropdown {
      margin-bottom: 16px;
    }

    .button {
      height: 40px;
    }
  }
`;
