/* eslint-disable import/no-named-as-default */
import React, { useContext, useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import Button from 'components/Button';
import FoldersListItem from './FoldersListItem';
import FCSidebarContext from '../FCSidebarContext';
import InputRename from '../../components/InputRename';

const FCSidebarLeft: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const {
    rootFolders,
    variables,
    openFolder,
    createFolder,
    handleFolderClick,
    isMobile
  } = useContext(FCSidebarContext);
  const [newFolder, setNewFolder] = useState<string | null>(null);
  const creatingNewFolder = typeof newFolder === 'string';

  const handleCreateFolderClick = () => {
    if (newFolder === null) setNewFolder('');
  };

  const saveNewFolder = () => {
    createFolder(newFolder!);
    setNewFolder(null);
  };

  return (
    <StyledFolders className="folders scrollable-fct">
      <Accordion label="Text Blocks" defaultOpen={!isMobile} data-cy="accordion_text_blocks">
        {rootFolders.map(item => (
          <FoldersListItem
            key={item.id}
            data-key={item.id}
            name={item.name}
            hasNodes={!!item.children?.length}
            onClick={() => handleFolderClick(item.id)}
            className={clsx('folder', { open: openFolder === item.id })}
            data-cy={`folder_${item.id}`}
          />
        ))}
        <div
          onClick={handleCreateFolderClick}
          className={clsx('create-folder', { active: creatingNewFolder })}
          role="button"
          tabIndex={0}
          data-cy="create_folder">
          <Icon name="plus-mini" />
          {creatingNewFolder ? (
            <>
              <InputRename
                value={newFolder!}
                onChange={e => setNewFolder(e.target.value)}
                onEnter={saveNewFolder}
                data-cy="input_folder_name"
              />
              <Button
                onClick={() => setNewFolder(null)}
                className="edit-cancel"
                simple
                data-cy="button_cancel">
                <Icon name="cancel-mini" />
              </Button>
              <Button onClick={saveNewFolder} className="edit-confirm" simple data-cy="button_save">
                <Icon name="checkmark" />
              </Button>
            </>
          ) : (
            'Create new folder'
          )}
        </div>
      </Accordion>
      <Accordion label="Insert Field" data-cy="accordion_insert_field">
        {variables.map(item => (
          <FoldersListItem
            key={item.id}
            data-key={item.id}
            name={item.name}
            hasNodes={!!item.children.length}
            onClick={() => handleFolderClick(item.id)}
            className={clsx('folder', { open: openFolder === item.id })}
            data-cy={`folder_${item.id}`}
          />
        ))}
      </Accordion>
    </StyledFolders>
  );
};

export default FCSidebarLeft;

const StyledFolders = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .create-folder {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    margin-left: 16px;
    padding: 8px 12px;
    color: ${props => props.theme.colors.red};

    &:not(.active) {
      cursor: pointer;
    }

    input {
      color: initial;
    }

    .icon {
      flex-shrink: 0;

      &.icon-plus-mini {
        margin: 4px 11px 4px 2px;
        fill: ${props => props.theme.colors.red};
      }

      &.icon-plus-mini {
        margin: 4px 11px 4px 2px;
        fill: ${props => props.theme.colors.red};
      }
      &.icon-cancel-mini {
        width: 8px;
        height: 8px;
        fill: ${props => props.theme.colors.gray};
      }
      &.icon-checkmark {
        fill: ${props => props.theme.colors.red};
      }
    }

    .edit-cancel {
      margin: 0 10px;
    }
  }
`;
