import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import NoSSR from 'components/NoSSR';
import ResetCSS from 'components/ResetCSS';
import { FCBrowser, FCEditDocument } from 'views/FormCreationPage';

export default () => (
  <NoSSR>
    <ResetCSS />
    <Router>
      <PrivateRoute path="/form-creation" component={FCBrowser} />
      <PrivateRoute path="/form-creation/:id" component={FCEditDocument} />
    </Router>
  </NoSSR>
);
