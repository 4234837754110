import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { styled } from 'styles';

import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Search from 'components/Search';
import { FCBrowserContext } from './FCBrowserWrapper';

const BrowserHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const { search, searchString } = useContext(FCBrowserContext);
  const layout = useLayout();
  const [openSearch, setOpenSearch] = useState(false);

  const handleChange = e => search(e.target.value);

  const handleClear = searchString ? () => search('') : undefined;

  const searchInput = (
    <Search
      value={searchString}
      onChange={handleChange}
      clear={handleClear}
      icon="search"
      placeholder="Search"
      className="search"
      data-cy="input_search"
    />
  );

  const mobileLayout = (
    <>
      <Button
        simple
        className={clsx('search-button', { active: openSearch })}
        onClick={() => setOpenSearch(value => !value)}
        data-cy="button_open_search">
        <Icon name="search" />
      </Button>
      <Link to="/form-creation/new" data-cy="link_create_new">
        <Icon name="fc-new-form" />
      </Link>
    </>
  );

  const desktopLayout = (
    <>
      {searchInput}
      <Link to="/form-creation/new" title="Create New Form" data-cy="link_create_new">
        Create New Form
      </Link>
    </>
  );

  return (
    <StyledBrowserHeader className={clsx('header', className)}>
      <h1 className="title">Form Creation</h1>
      <div className="actions">{layout === 'mobile' ? mobileLayout : desktopLayout}</div>
      {layout === 'mobile' && openSearch && searchInput}
    </StyledBrowserHeader>
  );
};

export default React.memo(BrowserHeader);

const StyledBrowserHeader = styled.div`
  padding: 20px 40px;
  border-bottom: 1px solid ${props => props.theme.colors.seashell};

  &.mobile-header {
    flex-wrap: wrap;

    .search {
      margin-top: 4px;
      flex: 1 1 100%;
    }
  }

  h1 {
    margin: 4px 0 24px;
    font-weight: bold;
    line-height: 24px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${props => props.theme.colors.red};
      border-radius: 3px;
      text-decoration: none;
    }
  }

  .search {
    margin: 0;
    .input-wrapper .input-component {
      height: 40px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .actions {
      a {
        padding: 8px 24px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: white;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: none;

    h1 {
      margin: 0 0 8px;
      font-weight: 600;
      line-height: 32px;
    }

    .actions {
      margin-bottom: 8px;
      display: flex;

      button,
      a {
        width: 32px;
        height: 32px;
        margin-left: 8px;
        -webkit-appearance: none;

        &:focus,
        &:active {
          outline: none;
        }

        &.search-button {
          background: ${props => props.theme.colors.pink};

          &.active {
            border: 1px solid ${props => props.theme.colors.red};
          }

          .icon {
            fill: ${props => props.theme.colors.red};
          }
        }
      }

      a {
        background: ${props => props.theme.colors.red};

        .icon {
          fill: white;
        }
      }
    }
  }
`;
