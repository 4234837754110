import React, { useContext } from 'react';
import { Editor as TinyMCE, IAllProps as EditorProps } from '@tinymce/tinymce-react';

import { styled, theme } from 'styles';
import TinyMCEContext from './TinyMCEContext';
import documentConfig from './documentContig.json';

const apiKey = process.env.GATSBY_TINYMCE_APIKEY;

const contentStyles = `
  body {
    box-sizing: border-box;
    font-family: 'Times New Roman', Times, serif;
    padding: ${documentConfig.padding};
    margin: auto;
    width: ${documentConfig.width};
    min-height: ${documentConfig.height};
  }

  .no-spacing {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .variable {
    color: #DF1721;
    background: #FFE3E5;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    body {
      padding: 20px;
    }
  }
`;

const Editor = () => {
  const { value, setValue, setSelection, setEditor } = useContext(TinyMCEContext);

  const handleEditorChange = (value, editor) => {
    const node = editor.selection.getNode();
    if (node.className === 'variable') {
      editor.dom.removeAllAttribs(node);
    }
    setValue(value);
  };

  const editorConfig: EditorProps = {
    init: {
      setup: editor => {
        editor.shortcuts.add('ctrl+13', 'Page break', 'mcePageBreak');
      },
      body_id: 'tinymce_xp_body_id',
      contextmenu: 'save_textblock',
      content_style: contentStyles,
      height: '100%',
      menubar: false,
      statusbar: false,
      plugins: 'advlist lists pagebreak tabontab lineheight',
      toolbar: `undo redo | italic bold underline |
      fontselect fontsizeselect formatselect |
      alignleft aligncenter alignright alignjustify |
      numlist bullist | outdent indent backcolor | pagebreak lineheightselect`,
      powerpaste_html_import: 'prompt',
      spellchecker_language: 'en',
      browser_spellcheck: true,
      external_plugins: {
        lineheight: '/tinymcePlugins/lineheight.js',
        tabontab: '/tinymcePlugins/tabontab.js',
        powerpaste: `https://cdn.tiny.cloud/1/${apiKey}/tinymce/4.9.10-80/plugins/powerpaste/plugin.min.js`
      }
    }
  };

  const handleInit = (_, editor) => {
    setEditor(editor);
  };

  const handleSelectionChange = (_, editor) => {
    setSelection(editor.selection.getContent());
  };

  return (
    <StyledEditor className="editor">
      <div id="toolbar_container" />
      <TinyMCE
        id="tinymce_xp_body_id"
        apiKey={apiKey}
        value={value}
        onInit={handleInit}
        onEditorChange={handleEditorChange}
        onSelectionChange={handleSelectionChange}
        {...editorConfig}
      />
    </StyledEditor>
  );
};

export default Editor;

const StyledEditor = styled.div`
  height: 100%;

  .tox-tinymce {
    box-sizing: border-box;
    margin: auto;
    max-width: 900px;
  }
`;
