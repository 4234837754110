import React from 'react';
import clsx from 'clsx';
import { styled } from 'styles';
import Button from 'components/Button';
import Modal from 'components/Modal';

interface ConfirmationDialogProps {
  modalTitle?: React.ReactNode;
  description?: React.ReactNode;
  open: boolean;
  className?: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  onSubmit,
  onClose,
  open,
  className,
  children,
  ...props
}) => {
  return (
    <StyledConfirm
      className={clsx('confirm', className)}
      onClose={onClose}
      open={open}
      data-cy="modal_confirm"
      {...props}>
      {children}
      <Button onClick={onClose} large className="cancel-btn" data-cy="button_discard">
        Discard
      </Button>
      <Button onClick={onSubmit} large data-cy="button_delete">
        Delete
      </Button>
    </StyledConfirm>
  );
};

export default ConfirmationDialog;

const StyledConfirm = styled(Modal)`
  font-size: 16px;
  line-height: 24px;

  .modal-content {
    min-width: 300px;
    width: 300px;
  }

  .modal-title {
    padding: 12px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .close-btn {
    padding: 0;
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    border-radius: ${props => props.theme.misc.borderRadius};

    svg {
      width: 8px;
      height: 8px;
    }
  }

  button.large {
    width: 100%;
    margin-top: 16px;

    &.cancel-btn {
      color: ${props => props.theme.colors.red};
      background: ${props => props.theme.colors.pink};
    }
  }
`;
