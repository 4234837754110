import React, { useRef, useEffect } from 'react';
import { styled } from 'styles';

interface InputRenameProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>;
  onEnter?: (value: string) => void;
  resetOnEnter?: boolean;
}

const InputRename = React.forwardRef<any, InputRenameProps>(
  ({ onEnter, onClick, resetOnEnter = false, ...props }, refProp: any) => {
    const refHook = useRef<HTMLInputElement | null>(null);
    const ref = refProp || refHook;

    const handleKeyPress = e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (onEnter) onEnter(e.target.value);
        if (resetOnEnter) {
          e.target.blur();
          e.target.value = '';
        }
      }
    };

    const handleClick = e => {
      e.target.focus();
      if (onClick) onClick(e);
    };

    useEffect(() => {
      if (ref.current) ref.current.focus();
    }, [ref]);

    return (
      <StyledInputRename ref={ref} onClick={handleClick} onKeyPress={handleKeyPress} {...props} />
    );
  }
);
export default React.memo(InputRename);

const StyledInputRename = styled.input`
  border: none;
  padding: 0;
  width: 110px;
  caret-color: ${props => props.theme.colors.red};
  outline: none;

  &::placeholder {
    color: black;
  }
`;
