import React, { useState, useCallback, useRef } from 'react';
import { VariableInserted, EditorContextValues, Variable, TextBlock } from '../../types';

type ContextValues = EditorContextValues & {
  editor: any;
  setEditor: React.Dispatch<any>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  selection: string;
  setSelection: React.Dispatch<React.SetStateAction<string>>;
};

const TinyMCEContext = React.createContext<ContextValues>({} as ContextValues);

export const transformFragmentToString = fragment => {
  switch (typeof fragment) {
    case 'string':
      // eslint-disable-next-line no-case-declarations
      const stripped = fragment.replace(/(<[^>]*>?)|(&emsp;|&nbsp;)/gm, '');
      return stripped;
    default:
      return '';
  }
};

export const TinyMCEContextWrapper = ({ children }) => {
  const [editor, setEditor] = useState<{ [key: string]: any }>();
  const [value, _setValue] = useState('');
  const [selection, setSelection] = useState('');
  const valueChangeListener = useRef(() => undefined);

  const setValue = useCallback(newValue => {
    // eslint-disable-next-line no-param-reassign
    newValue = newValue.replace(/&lt;!-- pagebreak --&gt;/g, '<!-- pagebreak -->');
    _setValue(oldValue => {
      if (valueChangeListener.current && oldValue.length > 0) valueChangeListener.current();
      return newValue;
    });
    // if (newValue) editor?.undoManager.clear();
  }, []);

  const insertTextBlock = (textBlock: TextBlock) => {
    const value = textBlock.data;
    // eslint-disable-next-line no-unused-expressions
    editor?.execCommand('mceInsertContent', false, value);
  };

  const insertVariable = (variable: Variable) => {
    const id = editor?.dom.uniqueId(`variable`);
    let value = `<span
    id="${id}"
    data-id="${variable.id}"
    data-name="${variable.name}"
    data-key="${variable.key}"
    class="variable">{{${variable.key}}}</span>\u200b `;

    const isAfterZeroWidthSpace = editor?.selection.getNode().innerHTML.match('\u200b');
    if (!isAfterZeroWidthSpace) value = `\u200b${value}`;

    // eslint-disable-next-line no-unused-expressions
    editor?.execCommand('mceInsertContent', false, value);
  };

  const setEditorValue = (value = '') => {
    setValue(value);
  };

  const getTextBlockForSaving = () => {
    const selection = editor?.selection.getContent();
    return selection;
  };

  const editorContainsVariables = () => {
    const variables = editor?.dom.select('.variable');
    return variables.length > 0;
  };

  const getContentForSaving = () => {
    return value;
  };

  const getVariablesInserted = () => {
    const variables = editor?.dom.select('.variable').map(node => {
      const { id, name, key } = node.dataset;
      return { key: Number(id), name: key, label: name };
    });

    const result: VariableInserted[] = [];
    const map = new Map();
    variables.forEach(item => {
      if (!map.has(item.key)) {
        map.set(item.key, true); // set any value to Map
        result.push(item);
      }
    });

    return result;
  };

  const getPreviewContentAsHtml = (variables: VariableInserted[]) => {
    let documentWithVariables = value.replace(/\u200b/g, '');

    variables.forEach(variable => {
      const regExp = new RegExp(`{{${variable.name}}}`, 'g');
      documentWithVariables = documentWithVariables.replace(
        regExp,
        variable.value || variable.name
      );
    });

    return documentWithVariables;
  };

  const setValueChangeListener = useCallback(callback => {
    valueChangeListener.current = callback;
  }, []);

  const contextValues: ContextValues = {
    value,
    editor,
    selection,
    setValue,
    setEditor,
    setSelection,
    insertTextBlock,
    insertVariable,
    setEditorValue,
    getTextBlockForSaving,
    editorContainsVariables,
    getContentForSaving,
    getVariablesInserted,
    getPreviewContentAsHtml,
    transformFragmentToString,
    setValueChangeListener
  };

  return <TinyMCEContext.Provider value={{ ...contextValues }}>{children}</TinyMCEContext.Provider>;
};
export default TinyMCEContext;
