import React, { useContext } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Button from 'components/Button';
import { Variable } from '../../types';
import FCSidebarContext from '../FCSidebarContext';
import { StyledTextBlockItem } from './TextBlockItem';

interface VariableItemProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
  item: Variable;
}

const VariableItem: React.FC<VariableItemProps> = ({ item, className, active, ...props }) => {
  const { isTablet, isMobile, currentSelection, handlePasteSelection } = useContext(
    FCSidebarContext
  );

  const pasteButton =
    isTablet && !isMobile && currentSelection === item ? (
      <div className="paste-item">
        <Button onClick={handlePasteSelection}>Paste in document</Button>
      </div>
    ) : null;

  return (
    <StyledVariableItem className={clsx('variable', { active }, className)} {...props}>
      <div className="header">
        <h5 className="name">{item.name}</h5>
      </div>
      {pasteButton}
    </StyledVariableItem>
  );
};

export default VariableItem;

const StyledVariableItem = styled(StyledTextBlockItem)`
  position: relative;

  .header {
    margin-bottom: 0;

    .name {
      font-weight: normal;
      margin-bottom: initial;
    }
  }
`;
